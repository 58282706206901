import NetworkOrderPaymentMethod from "../entities/order-payment-methods/NetworkOrderPaymentMethod"
import OrderPaymentMethod from "../../domain/entities/order-payment-methods/OrderPaymentMethod"
import OrderReceivingMethodsMapper from "./OrderReceivingMethodsMapper"
import PaymentProvidersMapper from "./PaymentProvidersMapper"

export default class OrderPaymentMethodsMapper {
  mapNetworkToDomain({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: NetworkOrderPaymentMethod
  }): OrderPaymentMethod {
    return {
      id: orderPaymentMethod.id,
      name: orderPaymentMethod.name,
      type: orderPaymentMethod.type,
      isAvailable: orderPaymentMethod.isAvailable,
      isDefault: orderPaymentMethod.isDefault,
      paymentProviderType: orderPaymentMethod.paymentProviderType,
      position: orderPaymentMethod.position,
      orderReceivingMethods: orderPaymentMethod.orderReceivingMethods && orderPaymentMethod.orderReceivingMethods
        .map(orderReceivingMethod => new OrderReceivingMethodsMapper().mapNetworkToDomain({ orderReceivingMethod })),
      orderReceivingMethodIds: orderPaymentMethod.orderReceivingMethodIds,
      paymentProvider: orderPaymentMethod.paymentProvider && new PaymentProvidersMapper()
        .mapNetworkToDomain({ paymentProvider: orderPaymentMethod.paymentProvider })
    }
  }

  mapDomainToNetwork({
    orderPaymentMethod
  }: {
    readonly orderPaymentMethod: OrderPaymentMethod
  }): NetworkOrderPaymentMethod {
    return new NetworkOrderPaymentMethod({
      id: orderPaymentMethod.id,
      name: orderPaymentMethod.name,
      type: orderPaymentMethod.type,
      isAvailable: orderPaymentMethod.isAvailable,
      isDefault: orderPaymentMethod.isDefault,
      paymentProviderType: orderPaymentMethod.paymentProviderType,
      position: orderPaymentMethod.position,
      orderReceivingMethods: orderPaymentMethod.orderReceivingMethods && orderPaymentMethod.orderReceivingMethods
        .map(orderReceivingMethod => new OrderReceivingMethodsMapper().mapDomainToNetwork({ orderReceivingMethod })),
      orderReceivingMethodIds: orderPaymentMethod.orderReceivingMethodIds,
      paymentProvider: orderPaymentMethod.paymentProvider && new PaymentProvidersMapper()
        .mapDomainToNetwork({ paymentProvider: orderPaymentMethod.paymentProvider })
    })
  }
}
