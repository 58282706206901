import { Expose, Type } from "class-transformer"
import OrderPaymentMethodType from "../../../domain/entities/order-payment-methods/OrderPaymentMethodType"
import NetworkOrderReceivingMethod from "../order-receiving-methods/NetworkOrderReceivingMethod"
import NetworkPaymentProvider from "../payment-providers/NetworkPaymentProvider"

export default class NetworkOrderPaymentMethod {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly type: OrderPaymentMethodType | null | undefined

  @Expose()
  readonly paymentProviderType: string | null | undefined

  @Expose()
  readonly isAvailable: boolean | null | undefined

  @Expose()
  readonly isDefault: boolean | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingMethod)
  readonly orderReceivingMethods: NetworkOrderReceivingMethod[] | null | undefined

  @Expose()
  readonly orderReceivingMethodIds: number[] | null | undefined

  @Expose()
  @Type(() => NetworkPaymentProvider)
  readonly paymentProvider: NetworkPaymentProvider | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly type: OrderPaymentMethodType | null | undefined
    readonly name: string | null | undefined
    readonly paymentProviderType: string | null | undefined
    readonly isAvailable: boolean | null | undefined
    readonly isDefault: boolean | null | undefined
    readonly position: number | null | undefined
    readonly orderReceivingMethods: NetworkOrderReceivingMethod[] | null | undefined
    readonly orderReceivingMethodIds: number[] | null | undefined
    readonly paymentProvider: NetworkPaymentProvider | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.type = parameters.type
    this.name = parameters.name
    this.paymentProviderType = parameters.paymentProviderType
    this.isAvailable = parameters.isAvailable
    this.isDefault = parameters.isDefault
    this.position = parameters.position
    this.orderReceivingMethods = parameters.orderReceivingMethods
    this.orderReceivingMethodIds = parameters.orderReceivingMethodIds
    this.paymentProvider = parameters.paymentProvider
  }
}
