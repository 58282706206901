import Option from "../../domain/entities/options/Option"
import NetworkOption from "../entities/options/NetworkOption"
import OptionValuesMapper from "./OptionValuesMapper"

export default class OptionsMapper {
  mapNetworkToDomain({
    option
  }: {
    readonly option: NetworkOption
  }): Option {
    return {
      id: option.id,
      externalCode: option.externalCode,
      name: option.name,
      position: option.position,
      displayStyle: option.displayStyle,
      values: option.values && option.values.map(value => {
        return new OptionValuesMapper().mapNetworkToDomain({ value })
      })
    }
  }

  mapDomainToNetwork({
    option
  }: {
    readonly option: Option
  }): NetworkOption {
    return new NetworkOption({
      id: option.id,
      externalCode: option.externalCode,
      name: option.name,
      position: option.position,
      displayStyle: option.displayStyle,
      values: option.values && option.values.map(value => {
        return new OptionValuesMapper().mapDomainToNetwork({ value })
      })
    })
  }
}
