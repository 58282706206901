import NetworkOrder from "../entities/orders/NetworkOrder"
import Order from "../../domain/entities/orders/Order"
import OrderCommentsMapper from "./OrderCommentsMapper"
import OrderProcessingStatusesMapper from "./OrderProcessingStatusesMapper"
import OrderReceivingsMapper from "./OrderReceivingsMapper"
import OrderPaymentsMapper from "./OrderPaymentsMapper"
import UsersMapper from "./UsersMapper"
import OrderItemsMapper from "./OrderItemsMapper"

export default class OrdersMapper {
  mapNetworkToDomain({
    order
  }: {
    readonly order: NetworkOrder
  }): Order {
    return {
      id: order.id,
      number: order.number,
      externalCode: order.externalCode,
      orderComment: order.orderComment && new OrderCommentsMapper()
        .mapNetworkToDomain({ orderComment: order.orderComment }),
      processingStatus: order.processingStatus && new OrderProcessingStatusesMapper()
        .mapNetworkToDomain({ orderProcessingStatus: order.processingStatus }),
      orderedAt: order.orderedAt,
      orderReceiving: order.orderReceiving && new OrderReceivingsMapper()
        .mapNetworkToDomain({ orderReceiving: order.orderReceiving }),
      orderPayment: order.orderPayment && new OrderPaymentsMapper()
        .mapNetworkToDomain({ orderPayment: order.orderPayment }),
      user: order.user && new UsersMapper()
        .mapNetworkToDomain({ user: order.user }),
      items: order.items && order.items.map(orderItem => {
        return new OrderItemsMapper().mapNetworkToDomain({ orderItem: orderItem })
      }),
      processingStatusId: order.processingStatusId
    }
  }

  mapDomainToNetwork({
    order
  }: {
    readonly order: Order
  }): NetworkOrder {
    return new NetworkOrder({
      id: order.id,
      externalCode: order.externalCode,
      number: order.number,
      orderComment: order.orderComment && new OrderCommentsMapper()
        .mapDomainToNetwork({ orderComment: order.orderComment }),
      processingStatus: order.processingStatus && new OrderProcessingStatusesMapper()
        .mapDomainToNetwork({ orderProcessingStatus: order.processingStatus }),
      orderedAt: order.orderedAt,
      orderReceiving: order.orderReceiving && new OrderReceivingsMapper()
        .mapDomainToNetwork({ orderReceiving: order.orderReceiving }),
      orderPayment: order.orderPayment && new OrderPaymentsMapper()
        .mapDomainToNetwork({ orderPayment: order.orderPayment }),
      user: order.user && new UsersMapper()
        .mapDomainToNetwork({ user: order.user }),
      items: order.items && order.items.map(orderItem => {
        return new OrderItemsMapper().mapDomainToNetwork({ orderItem: orderItem })
      }),
      processingStatusId: order.processingStatusId
    })
  }
}
