import UrlProvider from "../../../../sqadmin/core/presentation/services/UrlProvider"

export default class CoreUrlProvider extends UrlProvider {
  buildProductsUrl(): string {
    return this.buildUrl({ path: this.buildProductsPath() })
  }

  buildProductUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildProductPath({ id }) })
  }

  buildNewProductUrl(): string {
    return this.buildUrl({ path: this.buildNewProductPath() })
  }

  buildProductCategoriesUrl(): string {
    return this.buildUrl({ path: this.buildProductCategoriesPath() })
  }

  buildProductCategoryUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildProductCategoryPath({ id }) })
  }

  buildNewProductCategoryUrl(): string {
    return this.buildUrl({ path: this.buildNewProductCategoryPath() })
  }

  buildOptionsUrl(): string {
    return this.buildUrl({ path: this.buildOptionsPath() })
  }

  buildDashboardsUrl(): string {
    return this.buildUrl({ path: this.buildDashboardsPath() })
  }

  buildOptionUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildOptionPath({ id }) })
  }

  buildNewOptionUrl(): string {
    return this.buildUrl({ path: this.buildNewOptionPath() })
  }

  buildProductsPath(): string {
    return "products"
  }

  buildNewProductPath(): string {
    return `${this.buildProductsPath()}/new`
  }

  buildProductPath({ id }: { readonly id: number | string }): string {
    return `${this.buildProductsPath()}/${id}`
  }

  buildProductCategoriesPath(): string {
    return "product_categories"
  }

  buildNewProductCategoryPath(): string {
    return `${this.buildProductCategoriesPath()}/new`
  }

  buildProductCategoryPath({ id }: { readonly id: number | string }): string {
    return `${this.buildProductCategoriesPath()}/${id}`
  }

  buildOptionsPath(): string {
    return "options"
  }

  buildOptionPath({ id }: { readonly id: number | string }): string {
    return `${this.buildOptionsPath()}/${id}`
  }

  buildNewOptionPath(): string {
    return `${this.buildOptionsPath()}/new`
  }

  buildDashboardsPath(): string {
    return "dashboards"
  }

  buildDashboardPath({ id }: { readonly id: number | string }): string {
    return `${this.buildDashboardsPath()}/${id}`
  }

  buildDashboardUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildDashboardPath({ id }) })
  }

  buildNewDashboardPath(): string {
    return `${this.buildDashboardsPath()}/new`
  }

  buildNewDashboardUrl(): string {
    return this.buildUrl({ path: this.buildNewDashboardPath() })
  }

  buildPlacesUrl(): string {
    return this.buildUrl({ path: this.buildPlacesPath() })
  }

  buildPlacesPath(): string {
    return "places"
  }

  buildPlacePath({ id }: { readonly id: number | string }): string {
    return `${this.buildPlacesPath()}/${id}`
  }

  buildPlaceUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildPlacePath({ id }) })
  }

  buildNewPlacePath(): string {
    return `${this.buildPlacesPath()}/new`
  }

  buildNewPlaceUrl(): string {
    return this.buildUrl({ path: this.buildNewPlacePath() })
  }

  buildOrderProcessingStatusesUrl(): string {
    return this.buildUrl({ path: this.buildOrderProcessingStatusesPath() })
  }

  buildOrderProcessingStatusesPath(): string {
    return "order_processing_statuses"
  }

  buildNewOrderProcessingStatusPath(): string {
    return `${this.buildOrderProcessingStatusesPath()}/new`
  }

  buildOrderProcessingStatusPath({ id }: { readonly id: number | string }): string {
    return `${this.buildOrderProcessingStatusesPath()}/${id}`
  }

  buildNewOrderProcessingStatusUrl(): string {
    return this.buildUrl({ path: this.buildNewOrderProcessingStatusPath() })
  }

  buildOrderProcessingStatusUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildOrderProcessingStatusPath({ id }) })
  }

  buildPaymentStatusesUrl(): string {
    return this.buildUrl({ path: this.buildPaymentStatusesPath() })
  }

  buildPaymentStatusesPath(): string {
    return "payment_statuses"
  }

  buildNewPaymentStatusPath(): string {
    return `${this.buildPaymentStatusesPath()}/new`
  }

  buildPaymentStatusPath({ id }: { readonly id: number | string }): string {
    return `${this.buildPaymentStatusesPath()}/${id}`
  }

  buildNewPaymentStatusUrl(): string {
    return this.buildUrl({ path: this.buildNewPaymentStatusPath() })
  }

  buildPaymentStatusUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildPaymentStatusPath({ id }) })
  }

  buildOrdersPath(): string {
    return "orders"
  }

  buildOrderPath({ id }: { readonly id: number | string }): string {
    return `${this.buildOrdersPath()}/${id}`
  }

  buildOrderUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildOrderPath({ id }) })
  }

  buildOrdersUrl(): string {
    return this.buildUrl({ path: this.buildOrdersPath() })
  }

  buildPropertiesPath(): string {
    return "properties"
  }

  buildPropertyPath({ id }: { readonly id: number | string }): string {
    return `${this.buildPropertiesPath()}/${id}`
  }

  buildNewPropertyPath(): string {
    return `${this.buildPropertiesPath()}/new`
  }

  buildPropertiesUrl(): string {
    return this.buildUrl({ path: this.buildPropertiesPath() })
  }

  buildPropertyUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildPropertyPath({ id }) })
  }

  buildNewPropertyUrl(): string {
    return this.buildUrl({ path: this.buildNewPropertyPath() })
  }

  buildBonusProgramLevelsPath(): string {
    return "bonus_program_levels"
  }

  buildBonusProgramLevelPath({ id }: { readonly id: number | string }): string {
    return `${this.buildBonusProgramLevelsPath()}/${id}`
  }

  buildBonusProgramLevelUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildBonusProgramLevelPath({ id }) })
  }

  buildBonusProgramLevelsUrl(): string {
    return this.buildUrl({ path: this.buildBonusProgramLevelsPath() })
  }

  buildNewBonusProgramLevelUrl(): string {
    return this.buildUrl({ path: this.buildNewBonusProgramLevelPath() })
  }

  buildNewBonusProgramLevelPath(): string {
    return `${this.buildBonusProgramLevelsPath()}/new`
  }

  buildClientsPath(): string {
    return "clients"
  }

  buildClientsUrl(): string {
    return this.buildUrl({ path: this.buildClientsPath() })
  }

  buildClientPath({ id }: { readonly id: number | string }): string {
    return `${this.buildClientsPath()}/${id}`
  }

  buildClientUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildClientPath({ id }) })
  }

  buildAdminsPath(): string {
    return "admins"
  }

  buildAdminPath({ id }: { readonly id: number | string }): string {
    return `${this.buildAdminsPath()}/${id}`
  }

  buildAdminUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildAdminPath({ id }) })
  }

  buildNewAdminPath(): string {
    return `${this.buildAdminsPath()}/new`
  }

  buildNewAdminUrl(): string {
    return this.buildUrl({ path: this.buildNewAdminPath() })
  }

  buildAdminsUrl(): string {
    return this.buildUrl({ path: this.buildAdminsPath() })
  }

  buildDiscountRulesPath(): string {
    return "discount_rules"
  }

  buildDiscountRulePath({ id }: { readonly id: number | string }): string {
    return `${this.buildDiscountRulesPath()}/${id}`
  }

  buildDiscountRuleUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildDiscountRulePath({ id }) })
  }

  buildNewDiscountRulePath(): string {
    return `${this.buildDiscountRulesPath()}/new`
  }

  buildNewDiscountRuleUrl(): string {
    return this.buildUrl({ path: this.buildNewDiscountRulePath() })
  }

  buildDiscountRulesUrl(): string {
    return this.buildUrl({ path: this.buildDiscountRulesPath() })
  }

  buildOrderReceivingMethodsUrl(): string {
    return this.buildUrl({ path: this.buildOrderReceivingMethodsPath() })
  }

  buildOrderReceivingMethodsPath(): string {
    return "order_receiving_methods"
  }

  buildNewOrderReceivingMethodPath(): string {
    return `${this.buildOrderReceivingMethodsPath()}/new`
  }

  buildOrderReceivingMethodPath({ id }: { readonly id: number | string }): string {
    return `${this.buildOrderReceivingMethodsPath()}/${id}`
  }

  buildNewOrderReceivingMethodUrl(): string {
    return this.buildUrl({ path: this.buildNewOrderReceivingMethodPath() })
  }

  buildOrderReceivingMethodUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildOrderReceivingMethodPath({ id }) })
  }

  buildBonusPointsBurningRulePath(): string {
    return "bonus_points_burning_rule"
  }

  buildBonusPointsBurningRuleUrl(): string {
    return this.buildUrl({ path: this.buildBonusPointsBurningRulePath() })
  }

  buildOrderPaymentMethodsPath(): string {
    return "order_payment_methods"
  }

  buildOrderPaymentMethodPath({ id }: { readonly id: number | string }): string {
    return `${this.buildOrderPaymentMethodsPath()}/${id}`
  }

  buildOrderPaymentMethodUrl({ id }: { readonly id: number | string }): string {
    return this.buildUrl({ path: this.buildOrderPaymentMethodPath({ id }) })
  }

  buildNewOrderPaymentMethodPath(): string {
    return `${this.buildOrderPaymentMethodsPath()}/new`
  }

  buildNewOrderPaymentMethodUrl(): string {
    return this.buildUrl({ path: this.buildNewOrderPaymentMethodPath() })
  }

  buildOrderPaymentMethodsUrl(): string {
    return this.buildUrl({ path: this.buildOrderPaymentMethodsPath() })
  }
}
