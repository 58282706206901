import React from "react"
import Di, { createDi } from "./di/Di"
import EcommerceApp from "../sqcore/EcommerceApp"
import CoreTheme from "../sqadmin/core/presentation/entities/CoreTheme"
import logoUrl from "./assets/logo.svg"

// TODO: think how to run app with another DI.
const di: Di = createDi()
const coreTheme: CoreTheme = {
  logoUrl
}

export default function App() {
  return (
    <EcommerceApp
      di={di}
      coreTheme={coreTheme}
    />
  )
}
