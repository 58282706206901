import Configuration from "../../../../../../core/domain/entities/configurations/Configuration"
import ConfigurationEntityName from "../../../../../../core/domain/entities/configurations/ConfigurationEntityName"
import ConfigurationEntityPropertyName
  from "../../../../../../core/domain/entities/configurations/ConfigurationEntityPropertyName"
import ProductProperty from "../../../../../../core/domain/entities/product-properties/ProductProperty"
import { ObjectEditor } from "./ObjectEditor"

export class ProductPropertyEditor {
  private readonly objectEditor: ObjectEditor

  constructor(parameters: { configuration: Configuration | null | undefined }) {
    this.objectEditor = new ObjectEditor()
    this.objectEditor.init(parameters.configuration, ConfigurationEntityName.PRODUCT_PROPERTY_VALUE)
  }

  isPropertyDisabled(productProperty: ProductProperty) {
    return this.objectEditor.isDisabled(productProperty.id, ConfigurationEntityPropertyName.PROPERTY_ID)
  }

  isPropertyValueDisabled(productProperty: ProductProperty) {
    return this.objectEditor.isDisabled(productProperty.id, ConfigurationEntityPropertyName.PROPERTY_VALUE_ID)
  }

  canCreate() {
    return this.objectEditor.canCreate()
  }

  canDestroy(productProperty: ProductProperty) {
    return this.objectEditor.canDestroy(productProperty.id)
  }
}
