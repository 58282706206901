import RootDiComponent from "../../sqadmin/features/root/di/components/RootDiComponent"
import AuthenticationDiComponent from "../../sqadmin/features/authentication/di/components/AuthenticationDiComponent"
import ProductsDiComponent from "../../sqcore/features/products/di/components/ProductsDiComponent"
import SqAdminCoreDomainDiModule, {
  DefaultCoreDomainDiModule as DefaultSqAdminCoreDomainDiModule
} from "../../sqadmin/core/di/modules/CoreDomainDiModule"
import {
  DefaultCoreDataDiModule as DefaultSqAdminCoreDataDiModule
} from "../../sqadmin/core/di/modules/CoreDataDiModule"
import DefaultCoreSessionsNetworkDiModule from "../../sqcore/core/di/modules/DefaultCoreSessionsNetworkDiModule"
import { DefaultRootPresentationDiModule } from "../../sqadmin/features/root/di/modules/RootPresentationDiModule"
import {
  DefaultAuthenticationPresentationDiModule
} from "../../sqadmin/features/authentication/di/modules/AuthenticationPresentationDiModule"
import {
  DefaultProductsPresentationDiModule
} from "../../sqcore/features/products/di/modules/ProductsPresentationDiModule"
import SqAdminAuthenticationI18nDiModule, {
  DefaultAuthenticationI18nDiModule as DefaultSqAdminAuthenticationI18nDiModule
} from "../../sqadmin/features/authentication/di/modules/AuthenticationI18nDiModule"
import DefaultRuSqAdminAuthenticationTextProvider
  from "../../sqadmin/features/authentication/i18n/authentication-text-providers/DefaultRuAuthenticationTextProvider"
import SqAdminCoreDiComponent from "../../sqadmin/core/di/components/CoreDiComponent"
import SqAdminCoreI18nDiModule, {
  DefaultCoreI18nDiModule as DefaultSqAdminCoreI18nDiModule
} from "../../sqadmin/core/di/modules/CoreI18nDiModule"
import SqCoreCoreNetworkDiModule, {
  DefaultCoreNetworkDiModule as DefaultSqCoreCoreNetworkDiModule
} from "../../sqcore/core/di/modules/CoreNetworkDiModule"
import ProductCategoriesDiComponent
  from "../../sqcore/features/product-categories/di/components/ProductCategoriesDiComponent"
import {
  DefaultProductCategoriesPresentationDiModule
} from "../../sqcore/features/product-categories/di/modules/ProductCategoriesPresentationDiModule"
import SqAdminCoreLocalDiModule, {
  DefaultCoreLocalDiModule as DefaultSqAdminCoreLocalDiModule
} from "../../sqadmin/core/di/modules/CoreLocalDiModule"
import SqCoreCoreDiComponent from "../../sqcore/core/di/components/CoreDiComponent"
import SqCoreCoreI18nDiModule, {
  DefaultCoreI18nDiModule as DefaultSqCoreCoreI18nDiModule
} from "../../sqcore/core/di/modules/CoreI18nDiModule"
import DefaultRuSqCoreCoreTextProvider from "../../sqcore/core/i18n/core-text-providers/DefaultRuCoreTextProvider"
import ObjectsDomainDiModule, {
  DefaultObjectsDomainDiModule as DefaultSqAdminObjectsDomainDiModule
} from "../../sqadmin/features/objects/di/modules/ObjectsDomainDiModule"
import { DefaultObjectsDataDiModule } from "../../sqadmin/features/objects/di/modules/ObjectsDataDiModule"
import { DefaultProductsDomainDiModule } from "../../sqcore/features/products/di/modules/ProductsDomainDiModule"
import { DefaultProductsDataDiModule } from "../../sqcore/features/products/di/modules/ProductsDataDiModule"
import SqCoreCoreDataDiModule, {
  DefaultCoreDataDiModule as DefaultSqCoreCoreDataDiModule
} from "../../sqcore/core/di/modules/CoreDataDiModule"
import {
  DefaultOptionsPresentationDiModule
} from "../../sqcore/features/options/di/modules/OptionsPresentationDiModule"
import OptionsDiComponent from "../../sqcore/features/options/di/components/OptonsDiComponent"
import { DefaultOptionsDomainDiModule } from "../../sqcore/features/options/di/modules/OptionsDomainDiModule"
import { DefaultOptionsDataDiModule } from "../../sqcore/features/options/di/modules/OptionsDataDiModule"
import DashboardsDiComponent from "../../sqcore/features/dashboards/di/components/DashboardsDiComponent"
import {
  DefaultDashboardsPresentationDiModule
} from "../../sqcore/features/dashboards/di/modules/DashboardsPresentationDiModule"
import { DefaultDashboardsDataDiModule } from "../../sqcore/features/dashboards/di/modules/DashboardsDataDiModule"
import { DefaultDashboardsDomainDiModule } from "../../sqcore/features/dashboards/di/modules/DashboardsDomainDiModule"
import PlacesDiComponent from "../../sqcore/features/places/di/components/PlacesDiComponent"
import { DefaultPlacesPresentationDiModule } from "../../sqcore/features/places/di/modules/PlacesPresentationDiModule"
import { DefaultPlacesDomainDiModule } from "../../sqcore/features/places/di/modules/PlacesDomainDiModule"
import { DefaultPlacesDataDiModule } from "../../sqcore/features/places/di/modules/PlacesDataDiModule"
import OrderProcessingStatusesDiComponent
  from "../../sqcore/features/order-processing-statuses/di/components/OrderProcessingStatusesDiComponent"
import {
  DefaultOrderProcessingStatusesPresentationDiModule
} from "../../sqcore/features/order-processing-statuses/di/modules/OrderProcessingStatusesPresentationDiModule"
import {
  DefaultOrderProcessingStatusesDomainDiModule
} from "../../sqcore/features/order-processing-statuses/di/modules/OrderProcessingStatusesDomainDiModule"
import {
  DefaultOrderProcessingStatusesDataDiModule
} from "../../sqcore/features/order-processing-statuses/di/modules/OrderProcessingStatusesDataDiModule"
import OrdersDiComponent from "../../sqcore/features/orders/di/components/OrdersDiComponent"
import { DefaultOrdersPresentationDiModule } from "../../sqcore/features/orders/di/modules/OrdersPresentationDiModule"
import { DefaultOrdersDomainDiModule } from "../../sqcore/features/orders/di/modules/OrdersDomainDiModule"
import { DefaultOrdersDataDiModule } from "../../sqcore/features/orders/di/modules/OrdersDataDiModule"
import PropertiesDiComponent from "../../sqcore/features/properties/di/components/PropertiesDiComponent"
import {
  DefaultPropertiesPresentationDiModule
} from "../../sqcore/features/properties/di/modules/PropertiesPresentationDiModule"
import DefaultRuSqAdminCoreTextProvider from "../sqadmin/core/i18n/DefaultRuSqAdminCoreTextProvider"
import BonusProgramLevelsDiComponent
  from "../../sqcore/features/bonus-program-levels/di/components/BonusProgramLevelsDiComponent"
import {
  DefaultBonusProgramLevelsPresentationDiModule
} from "../../sqcore/features/bonus-program-levels/di/modules/BonusProgramLevelsPresentationDiModule"
import {
  DefaultBonusProgramLevelsCoreDomainDiModule
} from "../../sqcore/features/bonus-program-levels-core/di/BonusProgramLevelsCoreDomainDiModule"
import {
  DefaultBonusProgramLevelsCoreDataDiModule
} from "../../sqcore/features/bonus-program-levels-core/di/BonusProgramLevelsCoreDataDiModule"
import ClientsDiComponent from "../../sqcore/features/clients/di/components/ClientsDiComponent"
import {
  DefaultClientsPresentationDiModule
} from "../../sqcore/features/clients/di/modules/ClientsPresentationDiModule"
import { DefaultUsersCoreDataDiModule } from "../../sqcore/features/users-core/di/UsersCoreDataDiModule"
import { DefaultUsersCoreDomainDiModule } from "../../sqcore/features/users-core/di/UsersCoreDomainDiModule"
import AdminsDiComponent from "../../sqcore/features/admins/di/components/AdminsDiComponent"
import { DefaultAdminsPresentationDiModule } from "../../sqcore/features/admins/di/modules/AdminsPresentationDiModule"
import {
  DefaultPaymentStatusesPresentationDiModule
} from "../../sqcore/features/payment-statuses/di/modules/PaymentStatusesPresentationDiModule"
import {
  DefaultPaymentStatusesCoreDomainDiModule
} from "../../sqcore/features/payment-statuses-core/di/PaymentStatusesCoreDomainDiModule"
import {
  DefaultPaymentStatusesCoreDataDiModule
} from "../../sqcore/features/payment-statuses-core/di/PaymentStatusesCoreDataDiModule"
import PaymentStatusesDiComponent from "../../sqcore/features/payment-statuses/di/components/PaymentStatusesDiComponent"
import EcommerceDi from "../../sqcore/di/EcommerceDi"
import {
  DefaultConfigurationsCoreDataDiModule
} from "../../sqcore/features/configurations-core/di/ConfigurationsCoreDataDiModule"
import {
  DefaultConfigurationsCoreDomainDiModule
} from "../../sqcore/features/configurations-core/di/DefaultConfigurationsCoreDomainDiModule"
import DiscountRulesDiComponent from "../../sqcore/features/discount-rules/di/components/DiscountRulesDiComponent"
import {
  DefaultDiscountRulesPresentationDiModule
} from "../../sqcore/features/discount-rules/di/modules/DiscountRulesPresentationDiModule"
import {
  DefaultDiscountRulesCoreDomainDiModule
} from "../../sqcore/features/discount-rules-core/di/DiscountRulesCoreDomainDiModule"
import {
  DefaultDiscountRulesCoreDataDiModule
} from "../../sqcore/features/discount-rules-core/di/DiscountRulesCoreDataDiModule"
import {
  DefaultPropertyValuesCoreDomainDiModule
} from "../../sqcore/features/property-values-core/di/PropertyValuesCoreDomainDiModule"
import {
  DefaultPropertyValuesCoreDataDiModule
} from "../../sqcore/features/property-values-core/di/DefaultPropertyValuesCoreDataDiModule"
import {
  DefaultOptionValuesCoreDomainDiModule
} from "../../sqcore/features/option-values-core/di/OptionValuesCoreDomainDiModule"
import {
  DefaultOptionValuesCoreDataDiModule
} from "../../sqcore/features/option-values-core/di/DefaultOptionValuesCoreDataDiModule"
import OrderReceivingMethodsDiComponent
  from "../../sqcore/features/order-receiving-methods/di/components/OrderReceivingMethodsDiComponent"
import {
  DefaultOrderReceivingMethodsPresentationDiModule
} from "../../sqcore/features/order-receiving-methods/di/modules/OrderReceivingMethodsPresentationDiModule"
import {
  DefaultOrderReceivingMethodsCoreDomainDiModule
} from "../../sqcore/features/order-receiving-methods-core/di/OrderReceivingMethodsCoreDomainDiModule"
import {
  DefaultOrderReceivingMethodsCoreDataDiModule
} from "../../sqcore/features/order-receiving-methods-core/di/OrderReceivingMethodsCoreDataDiModule"
import {
  DefaultSettlementsCoreDataDiModule
} from "../../sqcore/features/settlements-core/di/SettlementsCoreDataDiModule"
import {
  DefaultSettlementsCoreDomainDiModule
} from "../../sqcore/features/settlements-core/di/SettlementsCoreDomainDiModule"
import BonusPointsBurningRulesDiComponent
  from "../../sqcore/features/bonus-points-burning-rules/di/components/BonusPointsBurningRulesDiComponent"
import {
  DefaultBonusPointsBurningRulesPresentationDiModule
} from "../../sqcore/features/bonus-points-burning-rules/di/modules/BonusPointsBurningRulesPresentationDiModule"
import {
  DefaultBonusPointsBurningRulesCoreDomainDiModule
} from "../../sqcore/features/bonus-points-burning-rules-core/di/BonusPointsBurningRulesCoreDomainDiModule"
import {
  DefaultBonusPointsBurningRulesCoreDataDiModule
} from "../../sqcore/features/bonus-points-burning-rules-core/di/BonusPointsBurningRulesCoreDataDiModule"
import {
  DefaultPropertiesCoreDomainDiModule
} from "../../sqcore/features/properties-core/di/PropertiesCoreDomainDiModule"
import { DefaultPropertiesCoreDataDiModule } from "../../sqcore/features/properties-core/di/PropertiesCoreDataDiModule"
import {
  DefaultProductCategoriesCoreDomainDiModule
} from "../../sqcore/features/product-categories-core/di/ProductCategoriesCoreDomainDiModule"
import {
  DefaultProductCategoriesCoreDataDiModule
} from "../../sqcore/features/product-categories-core/di/ProductCategoriesCoreDataDiModule"
import { DefaultProductsCoreDomainDiModule } from "../../sqcore/features/products-core/di/ProductsCoreDomainDiModule"
import { DefaultProductsCoreDataDiModule } from "../../sqcore/features/products-core/di/ProductsCoreDataDiModule"
import OrderPaymentMethodsDiComponent
  from "../../sqcore/features/order-payment-methods/di/components/OrderPaymentMethodsDiComponent"
import {
  DefaultOrderPaymentMethodsPresentationDiModule
} from "../../sqcore/features/order-payment-methods/di/modules/OrderPaymentMethodsPresentationDiModule"
import {
  DefaultOrderPaymentMethodsCoreDomainDiModule
} from "../../sqcore/features/order-payment-methods-core/di/OrderPaymentMethodsCoreDomainDiModule"
import {
  DefaultOrderPaymentMethodsCoreDataDiModule
} from "../../sqcore/features/order-payment-methods-core/di/OrderPaymentMethodsCoreDataDiModule"

const localStorageKeyPrefix = "SQCORE_ADMIN_EXAMPLE_"
const defaultLanguage = "RU"
const backendBaseUrl = process.env.REACT_APP_BACKEND_BASE_URL!

export default class Di implements EcommerceDi {
  readonly sqAdminCoreDiComponent: SqAdminCoreDiComponent
  readonly sqAdminRootDiComponent: RootDiComponent
  readonly sqAdminAuthenticationDiComponent: AuthenticationDiComponent
  readonly sqCoreCoreDiComponent: SqCoreCoreDiComponent
  readonly sqCoreProductsDiComponent: ProductsDiComponent
  readonly sqCoreProductCategoriesDiComponent: ProductCategoriesDiComponent
  readonly sqCoreOptionsDiComponent: OptionsDiComponent
  readonly sqCoreDashboardsDiComponent: DashboardsDiComponent
  readonly sqCorePlacesDiComponent: PlacesDiComponent
  readonly sqCoreOrderProcessingStatusesDiComponent: OrderProcessingStatusesDiComponent
  readonly sqCorePaymentStatusesDiComponent: PaymentStatusesDiComponent
  readonly sqCoreOrdersDiComponent: OrdersDiComponent
  readonly sqCorePropertiesDiComponent: PropertiesDiComponent
  readonly sqCoreBonusProgramLevelsDiComponent: BonusProgramLevelsDiComponent
  readonly sqCoreClientsDiComponent: ClientsDiComponent
  readonly sqCoreAdminsDiComponent: AdminsDiComponent
  readonly sqCoreDiscountRulesDiComponent: DiscountRulesDiComponent
  readonly sqCoreOrderReceivingMethodsDiComponent: OrderReceivingMethodsDiComponent
  readonly sqCoreBonusPointsBurningRulesDiComponent: BonusPointsBurningRulesDiComponent
  readonly sqCoreOrderPaymentMethodsDiComponent: OrderPaymentMethodsDiComponent

  constructor(parameters: {
    readonly sqAdminCoreDiComponent: SqAdminCoreDiComponent
    readonly sqAdminRootDiComponent: RootDiComponent
    readonly sqAdminAuthenticationDiComponent: AuthenticationDiComponent
    readonly sqCoreCoreDiComponent: SqCoreCoreDiComponent
    readonly sqCoreProductsDiComponent: ProductsDiComponent
    readonly sqCoreProductCategoriesDiComponent: ProductCategoriesDiComponent,
    readonly sqCoreOptionsDiComponent: OptionsDiComponent
    readonly sqCoreDashboardsDiComponent: DashboardsDiComponent
    readonly sqCorePlacesDiComponent: PlacesDiComponent
    readonly sqCoreOrderProcessingStatusesDiComponent: OrderProcessingStatusesDiComponent
    readonly sqCorePaymentStatusesDiComponent: PaymentStatusesDiComponent
    readonly sqCoreOrdersDiComponent: OrdersDiComponent
    readonly sqCorePropertiesDiComponent: PropertiesDiComponent
    readonly sqCoreBonusProgramLevelsDiComponent: BonusProgramLevelsDiComponent
    readonly sqCoreClientsDiComponent: ClientsDiComponent
    readonly sqCoreAdminsDiComponent: AdminsDiComponent
    readonly sqCoreDiscountRulesDiComponent: DiscountRulesDiComponent
    readonly sqCoreOrderReceivingMethodsDiComponent: OrderReceivingMethodsDiComponent
    readonly sqCoreBonusPointsBurningRulesDiComponent: BonusPointsBurningRulesDiComponent
    readonly sqCoreOrderPaymentMethodsDiComponent: OrderPaymentMethodsDiComponent
  }) {
    this.sqAdminCoreDiComponent = parameters.sqAdminCoreDiComponent
    this.sqAdminRootDiComponent = parameters.sqAdminRootDiComponent
    this.sqAdminAuthenticationDiComponent = parameters.sqAdminAuthenticationDiComponent
    this.sqCoreCoreDiComponent = parameters.sqCoreCoreDiComponent
    this.sqCoreProductsDiComponent = parameters.sqCoreProductsDiComponent
    this.sqCoreProductCategoriesDiComponent = parameters.sqCoreProductCategoriesDiComponent
    this.sqCoreOptionsDiComponent = parameters.sqCoreOptionsDiComponent
    this.sqCoreDashboardsDiComponent = parameters.sqCoreDashboardsDiComponent
    this.sqCorePlacesDiComponent = parameters.sqCorePlacesDiComponent
    this.sqCoreOrderProcessingStatusesDiComponent = parameters.sqCoreOrderProcessingStatusesDiComponent
    this.sqCoreOrdersDiComponent = parameters.sqCoreOrdersDiComponent
    this.sqCorePropertiesDiComponent = parameters.sqCorePropertiesDiComponent
    this.sqCoreBonusProgramLevelsDiComponent = parameters.sqCoreBonusProgramLevelsDiComponent
    this.sqCoreClientsDiComponent = parameters.sqCoreClientsDiComponent
    this.sqCoreAdminsDiComponent = parameters.sqCoreAdminsDiComponent
    this.sqCorePaymentStatusesDiComponent = parameters.sqCorePaymentStatusesDiComponent
    this.sqCoreDiscountRulesDiComponent = parameters.sqCoreDiscountRulesDiComponent
    this.sqCoreOrderReceivingMethodsDiComponent = parameters.sqCoreOrderReceivingMethodsDiComponent
    this.sqCoreBonusPointsBurningRulesDiComponent = parameters.sqCoreBonusPointsBurningRulesDiComponent
    this.sqCoreOrderPaymentMethodsDiComponent = parameters.sqCoreOrderPaymentMethodsDiComponent
  }
}

export function createDi(): Di {
  const sqAdminCoreI18nDiModule: SqAdminCoreI18nDiModule = new DefaultSqAdminCoreI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuSqAdminCoreTextProvider()
    }
  })

  const sqAdminAuthenticationI18nDiModule: SqAdminAuthenticationI18nDiModule =
    new DefaultSqAdminAuthenticationI18nDiModule({
      defaultLanguage,
      textProviderByLanguage: {
        "RU": new DefaultRuSqAdminAuthenticationTextProvider()
      }
    })

  const sqCoreCoreI18nDiModule: SqCoreCoreI18nDiModule = new DefaultSqCoreCoreI18nDiModule({
    defaultLanguage,
    textProviderByLanguage: {
      "RU": new DefaultRuSqCoreCoreTextProvider()
    }
  })

  const sqAdminCoreLocalDiModule: SqAdminCoreLocalDiModule = new DefaultSqAdminCoreLocalDiModule({
    localStorageKeyPrefix
  })

  const sqCoreCoreNetworkDiModule: SqCoreCoreNetworkDiModule = new DefaultSqCoreCoreNetworkDiModule({
    backendBaseUrl,
    coreLocalDiModule: sqAdminCoreLocalDiModule
  })

  const sqAdminCoreDomainDiModule: SqAdminCoreDomainDiModule = new DefaultSqAdminCoreDomainDiModule({
    coreDataDiModule: new DefaultSqAdminCoreDataDiModule({
      coreLocalDiModule: sqAdminCoreLocalDiModule,
      coreSessionsNetworkSourceDiModule: new DefaultCoreSessionsNetworkDiModule({
        coreNetworkDiModule: sqCoreCoreNetworkDiModule
      })
    })
  })

  const objectsDomainDiModule: ObjectsDomainDiModule = new DefaultSqAdminObjectsDomainDiModule({
    objectsDataDiModule: new DefaultObjectsDataDiModule()
  })

  const sqCoreDataDiModule: SqCoreCoreDataDiModule = new DefaultSqCoreCoreDataDiModule({
    sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
  })

  const bonusProgramLevelsCoreDomainDiModule = new DefaultBonusProgramLevelsCoreDomainDiModule({
    bonusProgramLevelsCoreDataDiModule: new DefaultBonusProgramLevelsCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const paymentStatusesCoreDomainDiModule = new DefaultPaymentStatusesCoreDomainDiModule({
    paymentStatusesCoreDataDiModule: new DefaultPaymentStatusesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const configurationsCoreDomainDiModule = new DefaultConfigurationsCoreDomainDiModule({
    configurationsCoreDataDiModule: new DefaultConfigurationsCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const discountRulesCoreDomainDiModule = new DefaultDiscountRulesCoreDomainDiModule({
    discountRulesCoreDataDiModule: new DefaultDiscountRulesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const propertyValuesCoreDomainDiModule = new DefaultPropertyValuesCoreDomainDiModule({
    propertyValuesCoreDataDiModule: new DefaultPropertyValuesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const optionValuesCoreDomainDiModule = new DefaultOptionValuesCoreDomainDiModule({
    optionValuesCoreDataDiModule: new DefaultOptionValuesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const settlementsCoreDomainDiModule = new DefaultSettlementsCoreDomainDiModule({
    settlementsCoreDataDiModule: new DefaultSettlementsCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const propertiesCoreDomainDiModule = new DefaultPropertiesCoreDomainDiModule({
    propertiesCoreDataDiModule: new DefaultPropertiesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const productCategoriesCoreDomainDiModule = new DefaultProductCategoriesCoreDomainDiModule({
    productCategoriesCoreDataDiModule: new DefaultProductCategoriesCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const productsCoreDomainDiModule = new DefaultProductsCoreDomainDiModule({
    productsCoreDataDiModule: new DefaultProductsCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  const orderReceivingMethodsCoreDomainDiModule = new DefaultOrderReceivingMethodsCoreDomainDiModule({
    orderReceivingMethodsCoreDataDiModule: new DefaultOrderReceivingMethodsCoreDataDiModule({
      sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
    })
  })

  return new Di({
    sqAdminCoreDiComponent: {
      coreI18nDiModule: sqAdminCoreI18nDiModule
    },
    sqAdminRootDiComponent: {
      rootPresentationDiModule: new DefaultRootPresentationDiModule({
        coreDomainDiModule: sqAdminCoreDomainDiModule
      })
    },
    sqAdminAuthenticationDiComponent: {
      authenticationI18nDiModule: sqAdminAuthenticationI18nDiModule,
      authenticationPresentationDiModule: new DefaultAuthenticationPresentationDiModule({
        coreDomainDiModule: sqAdminCoreDomainDiModule
      })
    },
    sqCoreCoreDiComponent: {
      coreI18nDiModule: sqCoreCoreI18nDiModule
    },
    sqCoreProductsDiComponent: {
      productsPresentationDiModule: new DefaultProductsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        productsDomainDiModule: new DefaultProductsDomainDiModule({
          productsDataDiModule: new DefaultProductsDataDiModule({
            coreDataDiModule: sqCoreDataDiModule
          })
        }),
        bonusProgramLevelsCoreDomainDiModule,
        configurationsCoreDomainDiModule,
        optionValuesCoreDomainDiModule,
        propertyValuesCoreDomainDiModule,
        propertiesCoreDomainDiModule,
        productCategoriesCoreDomainDiModule,
        productsCoreDomainDiModule
      })
    },
    sqCoreProductCategoriesDiComponent: {
      productCategoriesPresentationDiModule: new DefaultProductCategoriesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        productCategoriesCoreDomainDiModule,
        bonusProgramLevelsCoreDomainDiModule
      })
    },
    sqCoreOptionsDiComponent: {
      optionsPresentationDiModule: new DefaultOptionsPresentationDiModule({
          coreI18nDiModule: sqCoreCoreI18nDiModule,
          objectsDomainDiModule,
          optionsDomainDiModule:
            new DefaultOptionsDomainDiModule({
              sqCoreOptionsDataDiModule: new DefaultOptionsDataDiModule({
                coreDataDiModule: sqCoreDataDiModule
              })
            })
        }
      )
    },
    sqCoreDashboardsDiComponent: {
      dashboardsPresentationDiModule: new DefaultDashboardsPresentationDiModule({
          coreI18nDiModule: sqCoreCoreI18nDiModule,
          objectsDomainDiModule,
          dashboardsDomainDiModule:
            new DefaultDashboardsDomainDiModule({
              sqCoreDashboardsDataDiModule: new DefaultDashboardsDataDiModule({
                coreDataDiModule: sqCoreDataDiModule
              })
            }),
          propertyValuesCoreDomainDiModule: propertyValuesCoreDomainDiModule,
          propertiesCoreDomainDiModule,
          productCategoriesCoreDomainDiModule,
          productsCoreDomainDiModule
        }
      )
    },
    sqCorePlacesDiComponent: {
      placesPresentationDiModule: new DefaultPlacesPresentationDiModule({
          coreI18nDiModule: sqCoreCoreI18nDiModule,
          objectsDomainDiModule,
          placesDomainDiModule:
            new DefaultPlacesDomainDiModule({
              sqCorePlacesDataDiModule: new DefaultPlacesDataDiModule({
                coreDataDiModule: sqCoreDataDiModule
              })
            }),
          settlementsCoreDomainDiModule: settlementsCoreDomainDiModule
        }
      )
    },
    sqCoreOrderProcessingStatusesDiComponent: {
      orderProcessingStatusesPresentationDiModule: new DefaultOrderProcessingStatusesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        orderProcessingStatusesDomainDiModule:
          new DefaultOrderProcessingStatusesDomainDiModule({
            sqCoreOrderProcessingStatusesDataDiModule: new DefaultOrderProcessingStatusesDataDiModule({
              coreDataDiModule: sqCoreDataDiModule
            })
          })
      })
    },
    sqCoreOrdersDiComponent: {
      ordersPresentationDiModule: new DefaultOrdersPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        ordersDomainDiModule:
          new DefaultOrdersDomainDiModule({
            sqCoreOrdersDataDiModule: new DefaultOrdersDataDiModule({
              coreDataDiModule: sqCoreDataDiModule
            })
          }),
        paymentStatusesCoreDomainDiModule
      })
    },
    sqCorePropertiesDiComponent: {
      propertiesPresentationDiModule: new DefaultPropertiesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        propertiesCoreDomainDiModule
      })
    },
    sqCoreBonusProgramLevelsDiComponent: {
      bonusProgramLevelsPresentationDiModule: new DefaultBonusProgramLevelsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        bonusProgramLevelsCoreDomainDiModule,
        propertiesCoreDomainDiModule,
        propertyValuesCoreDomainDiModule,
        productCategoriesCoreDomainDiModule,
        productsCoreDomainDiModule
      })
    },
    sqCoreClientsDiComponent: {
      clientsPresentationDiModule: new DefaultClientsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        usersCoreDomainDiModule: new DefaultUsersCoreDomainDiModule({
          usersCoreDataDiModule: new DefaultUsersCoreDataDiModule({
            sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
          })
        })
      })
    },
    sqCoreAdminsDiComponent: {
      adminsPresentationDiModule: new DefaultAdminsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        usersCoreDomainDiModule: new DefaultUsersCoreDomainDiModule({
          usersCoreDataDiModule: new DefaultUsersCoreDataDiModule({
            sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
          })
        })
      })
    },
    sqCorePaymentStatusesDiComponent: {
      paymentStatusesPresentationDiModule: new DefaultPaymentStatusesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        paymentStatusesCoreDomainDiModule
      })
    },
    sqCoreDiscountRulesDiComponent: {
      discountRulesPresentationDiModule: new DefaultDiscountRulesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        discountRulesCoreDomainDiModule,
        propertyValuesCoreDomainDiModule,
        propertiesCoreDomainDiModule,
        productCategoriesCoreDomainDiModule,
        productsCoreDomainDiModule
      })
    },
    sqCoreOrderReceivingMethodsDiComponent: {
      orderReceivingMethodsPresentationDiModule: new DefaultOrderReceivingMethodsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        orderReceivingMethodsCoreDomainDiModule,
        settlementsCoreDomainDiModule: settlementsCoreDomainDiModule
      })
    },
    sqCoreBonusPointsBurningRulesDiComponent: {
      bonusPointsBurningRulesPresentationDiModule: new DefaultBonusPointsBurningRulesPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        bonusPointsBurningRulesCoreDomainDiModule: new DefaultBonusPointsBurningRulesCoreDomainDiModule({
          bonusPointsBurningRulesCoreDataDiModule: new DefaultBonusPointsBurningRulesCoreDataDiModule({
            sqCoreCoreNetworkDiModule: sqCoreCoreNetworkDiModule
          })
        })
      })
    },
    sqCoreOrderPaymentMethodsDiComponent: {
      orderPaymentMethodsPresentationDiModule: new DefaultOrderPaymentMethodsPresentationDiModule({
        coreI18nDiModule: sqCoreCoreI18nDiModule,
        objectsDomainDiModule,
        orderPaymentMethodsCoreDomainDiModule: new DefaultOrderPaymentMethodsCoreDomainDiModule({
          orderPaymentMethodsCoreDataDiModule: new DefaultOrderPaymentMethodsCoreDataDiModule({
            sqCoreCoreNetworkDiModule
          })
        }),
        orderReceivingMethodsCoreDomainDiModule
      })
    }
  })
}
