import { Expose, Type } from "class-transformer"
import OrderReceivingMethodType from "../../../domain/entities/order-receiving-methods/OrderReceivingMethodType"
import FulfillmentProviderType from "../../../domain/entities/order-receiving-methods/FulfillmentProviderType"
import NetworkOrderReceivingPriceRule from "../order-receiving-price-rules/NetworkOrderReceivingPriceRule"
import NetworkOrderShippingMethod from "../order-shipping-methods/NetworkOrderShippingMethod"

export default class NetworkOrderReceivingMethod {
  @Expose()
  readonly id: number | null | undefined

  @Expose()
  readonly type: OrderReceivingMethodType | null | undefined

  @Expose()
  readonly fulfillmentProviderType: FulfillmentProviderType | null | undefined

  @Expose()
  readonly name: string | null | undefined

  @Expose()
  readonly position: number | null | undefined

  @Expose()
  readonly isDefault: boolean | null | undefined

  @Expose()
  readonly isAvailable: boolean | null | undefined

  @Expose()
  @Type(() => NetworkOrderReceivingPriceRule)
  readonly orderReceivingPriceRules: NetworkOrderReceivingPriceRule[] | null | undefined

  @Expose()
  @Type(() => NetworkOrderShippingMethod)
  readonly orderShippingMethod: NetworkOrderShippingMethod | null | undefined

  constructor(parameters?: {
    readonly id: number | null | undefined
    readonly type: OrderReceivingMethodType | null | undefined
    readonly name: string | null | undefined
    readonly position: number | null | undefined
    readonly isDefault: boolean | null | undefined
    readonly isAvailable: boolean | null | undefined
    readonly fulfillmentProviderType: FulfillmentProviderType | null | undefined
    readonly orderReceivingPriceRules: NetworkOrderReceivingPriceRule[] | null | undefined
    readonly orderShippingMethod: NetworkOrderShippingMethod | null | undefined
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.type = parameters.type
    this.name = parameters.name
    this.position = parameters.position
    this.isDefault = parameters.isDefault
    this.fulfillmentProviderType = parameters.fulfillmentProviderType
    this.orderReceivingPriceRules = parameters.orderReceivingPriceRules
    this.orderShippingMethod = parameters.orderShippingMethod
    this.isAvailable = parameters.isAvailable
  }
}
