import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import autoBind from "auto-bind"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import UpdateOrderUseCase from "../../../domain/use-cases/orders/UpdateOrderUseCase"
import DestroyOrderUseCase from "../../../domain/use-cases/orders/DestroyOrderUseCase"
import GetOrderUseCase from "../../../domain/use-cases/orders/GetOrderUseCase"
import Order from "../../../../../core/domain/entities/orders/Order"
import OrderErrorsObject from "../../../../../core/domain/entities/orders/OrderErrorsObject"
import OrderError from "../../../../../core/domain/entities/orders/OrderError"
import OrderComment from "../../../../../core/domain/entities/order-comments/OrderComment"
import GetOrderProcessingStatusesUseCase
  from "../../../domain/use-cases/order-processing-statuses/GetOrderProcessingStatusesUseCase"
import OrderProcessingStatus from "../../../../../core/domain/entities/order-processing-statuses/OrderProcessingStatus"
import DateFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DateFormField"
import OrderReceivingMethod from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethod"
import OrderReceivingMethodType
  from "../../../../../core/domain/entities/order-receiving-methods/OrderReceivingMethodType"
import OrderPaymentMethod from "../../../../../core/domain/entities/order-payment-methods/OrderPaymentMethod"
import User from "../../../../../core/domain/entities/users/User"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import PhoneNumber from "../../../../../core/domain/entities/phone-numbers/PhoneNumber"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import { v4 as uuidv4 } from "uuid"
import OrderItem from "../../../../../core/domain/entities/order-items/OrderItem"
import OrderItemErrorsObject from "../../../../../core/domain/entities/order-items/OrderItemErrorsObject"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import VariantOption from "../../../../../core/domain/entities/variant-options/VariantOption"
import VariantOptionErrorsObject from "../../../../../core/domain/entities/variant-options/VariantOptionErrorsObject"
import {
  ListFormFieldDisplayType
} from "../../../../../../sqadmin/features/objects/presentation/components/form-field-by-type/list-form-field/ListFormFieldComponent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import GetPaymentStatusesUseCase from "../../../../payment-statuses-core/domain/use-cases/GetPaymentStatusesUseCase"
import PaymentStatus from "../../../../../core/domain/entities/payment-statuses/PaymentStatus"
import OrderPayment from "../../../../../core/domain/entities/order-payments/OrderPayment"
import FormFieldPlaceType
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/FormFieldPlaceType"
import TextFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/TextFormField"
import OrderReceiving from "../../../../../core/domain/entities/order-receivings/OrderReceiving"
import OrderReceivingRecipient
  from "../../../../../core/domain/entities/order-receiving-recipients/OrderReceivingRecipient"

export default class OrderViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getOrderUseCase: GetOrderUseCase
  private readonly updateOrderUseCase: UpdateOrderUseCase
  private readonly destroyOrderUseCase: DestroyOrderUseCase
  private readonly getOrderProcessingStatusesUseCase: GetOrderProcessingStatusesUseCase
  private readonly getPaymentStatusesUseCase: GetPaymentStatusesUseCase
  private readonly orderId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    Order,
    OrderError,
    OrderErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getOrderUseCase: GetOrderUseCase
    readonly updateOrderUseCase: UpdateOrderUseCase
    readonly destroyOrderUseCase: DestroyOrderUseCase
    readonly getOrderProcessingStatusesUseCase: GetOrderProcessingStatusesUseCase
    readonly getPaymentStatusesUseCase: GetPaymentStatusesUseCase
    readonly orderId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getOrderUseCase = parameters.getOrderUseCase
    this.updateOrderUseCase = parameters.updateOrderUseCase
    this.destroyOrderUseCase = parameters.destroyOrderUseCase
    this.getOrderProcessingStatusesUseCase = parameters.getOrderProcessingStatusesUseCase
    this.getPaymentStatusesUseCase = parameters.getPaymentStatusesUseCase
    this.orderId = parameters.orderId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Order,
    OrderError,
    OrderErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.orderId),
      buildObject: async() => ({
        id: undefined,
        externalCode: undefined,
        number: undefined,
        status: undefined,
        orderComment: undefined,
        processingStatus: undefined,
        orderedAt: undefined,
        orderReceiving: undefined,
        orderPayment: undefined,
        user: undefined,
        items: undefined,
        processingStatusId: undefined
      }),
      getObjectUrl: (order) => {
        return this.coreUrlProvider.buildOrderUrl({
          id: order.id!
        })
      },
      loadObject: async() => {
        return await this.getOrderUseCase.call({ orderId: this.orderId! })
      },
      updateObject: async({ object: order }) => {
        return await this.updateOrderUseCase.call({
          orderId: this.orderId!,
          order
        })
      },
      destroyObject: async() => {
        return await this.destroyOrderUseCase.call({ orderId: this.orderId! })
      },
      getErrorsObject: ({ error: orderError }) => orderError
        ?.errorsObject,
      formFields: [
        new StringFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.number(),
          getValue: (order: Order) => order.number,
          setValue: (order: Order, number: string): Order => ({
            ...order,
            number
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.number
        }),
        new StringFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (order: Order) => order.externalCode,
          setValue: (order: Order, externalCode: string): Order => ({
            ...order,
            externalCode
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.externalCode
        }),
        new StringFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.client(),
          getValue: (order: Order) => [
            this.formatUserFullName(order.user),
            this.formatPhoneNumber(order.user?.phoneNumber)
          ].filter(value => isPresent(value)).join(", "),
          setValue: (order: Order, _): Order => order,
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.user
            ?.attributes
            ?.profile
        }),
        new DateFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.orderedAt(),
          getValue: (order: Order) => order.orderedAt,
          setValue: (order: Order, orderedAt: Date | null | undefined): Order => ({
            ...order,
            orderedAt
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.orderedAt
        }),
        new SingleSelectFormField<Order, OrderErrorsObject, OrderProcessingStatus>({
          isSearchBarVisible: false,
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getOrderProcessingStatusesUseCase.call({
              filter: { query },
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getTitle: () => coreTextProvider.processingStatus(),
          getValue: (order: Order) => order.processingStatus,
          setValue: (order: Order, orderProcessingStatus: OrderProcessingStatus | null): Order => ({
            ...order,
            processingStatusId: orderProcessingStatus?.id,
            processingStatus: orderProcessingStatus
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.processingStatus,
          getOptionId: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.id!.toString(),
          getOptionText: (orderProcessingStatus: OrderProcessingStatus) => orderProcessingStatus.name
        }),
        new SingleSelectFormField<Order, OrderErrorsObject, OrderReceivingMethod>({
          getDisabled: () => true,
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: [],
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.receivingMethod(),
          getValue: (order: Order) => order.orderReceiving?.method,
          setValue: (order: Order, _): Order => order,
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.orderReceiving
            ?.attributes
            ?.method,
          getOptionId: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.id!.toString(),
          getOptionText: (orderReceivingMethod: OrderReceivingMethod) => orderReceivingMethod.name
        }),
        new TextFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: (order: Order) => order.orderReceiving?.method?.type === OrderReceivingMethodType.PICK_UP ?
            coreTextProvider.pickUpPoint() : coreTextProvider.address(),
          getValue: (order: Order) => this.formatedOrderReceivingValue(order.orderReceiving),
          setValue: (order: Order): Order => ({ ...order }),
          getErrors: () => undefined
        }),
        new StringFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.recipient(),
          getValue: (order: Order) => this.formatRecipientFullName(order.orderReceiving?.recipient),
          setValue: (order: Order, _): Order => order,
          getErrors: () => undefined
        }),
        new SingleSelectFormField<Order, OrderErrorsObject, OrderPaymentMethod>({
          getDisabled: () => true,
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: [],
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.paymentMethod(),
          getValue: (order: Order) => order.orderPayment?.method,
          setValue: (order: Order, _): Order => order,
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.orderPayment
            ?.attributes
            ?.method,
          getOptionId: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.id!.toString(),
          getOptionText: (orderPaymentMethod: OrderPaymentMethod) => orderPaymentMethod.name
        }),
        new SingleSelectFormField<Order, OrderErrorsObject, PaymentStatus>({
          isSearchBarVisible: false,
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getPaymentStatusesUseCase.call({
              filter: { query },
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getTitle: () => coreTextProvider.paymentStatus(),
          getValue: (order: Order) => order.orderPayment?.payment?.status,
          setValue: (order: Order, value: PaymentStatus | null): Order => ({
            ...order,
            orderPayment: {
              ...order.orderPayment ?? this.createOrderPayment(),
              payment: {
                ...order.orderPayment?.payment,
                statusId: value?.id,
                status: value
              }
            }
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.orderPayment
            ?.attributes
            ?.payment,
          getOptionId: (paymentStatus: PaymentStatus) => paymentStatus.id!.toString(),
          getOptionText: (paymentStatus: PaymentStatus) => paymentStatus.displayName
        }),
        new TextFormField<Order, OrderErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.comment(),
          getValue: (order: Order) => order.orderComment?.value,
          setValue: (order: Order, orderCommentValue: string): Order => ({
            ...order,
            orderComment: {
              ...order.orderComment ?? this.createOrderComment(),
              value: orderCommentValue
            }
          }),
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.orderComment
        }),
        new ListFormField<Order, OrderErrorsObject, OrderItem, OrderItemErrorsObject>({
          isAddObjectVisible: false,
          getRemoveObjectVisible: () => false,
          isEditVisible: true,
          getTitle: () => coreTextProvider.products(),
          getValue: (order: Order) => order.items,
          getErrors: (orderErrorsObject?: OrderErrorsObject) => orderErrorsObject
            ?.attributes
            ?.items,
          setValue: (order: Order, _): Order => order,
          getNestedObjectId: (orderItem: OrderItem) => orderItem.clientId!,
          getNestedObjectTitle: (_, index) => coreTextProvider
            .productWithNumber({ number: index + 1 }),
          getNestedErrorsObject: (orderItem, orderErrorsObject?: OrderErrorsObject) => {
            return orderErrorsObject?.items?.find(
              (orderItemErrorsObject: OrderItemErrorsObject) => {
                return orderItemErrorsObject.clientId === orderItem.clientId
              })
          },
          hasNestedErrorsObjects: (orderErrorsObject?: OrderErrorsObject) => {
            return isPresent(orderErrorsObject?.items)
          },
          buildNewValue: () => ({
            clientId: uuidv4(),
            id: undefined,
            price: undefined,
            count: undefined,
            batch: undefined
          }),
          fields: [
            new StringFormField<OrderItem, OrderItemErrorsObject>({
              getDisabled: () => true,
              getPlaceType: () => FormFieldPlaceType.MAIN,
              getTitle: () => undefined,
              getValue: (orderItem: OrderItem) => {
                const product = orderItem.batch?.variant?.product
                const hasBatches = product?.hasBatches ?? false
                return [
                  product?.name,
                  product?.category?.name,
                  hasBatches ? orderItem.batch?.externalCode : null
                ]
                  .filter((value) => isPresent(value))
                  .join(", ")
              },
              setValue: (orderItem: OrderItem, _): OrderItem => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.batch
                ?.variant
                ?.product
                ?.attributes
                ?.name
            }),
            new StringFormField<OrderItem, OrderItemErrorsObject>({
              getDisabled: () => true,
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              getTitle: () => coreTextProvider.name(),
              getValue: (orderItem: OrderItem) => orderItem.batch?.variant?.product?.name,
              setValue: (orderItem: OrderItem, _): OrderItem => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.batch
                ?.variant
                ?.product
                ?.attributes
                ?.name
            }),
            new StringFormField<OrderItem, OrderItemErrorsObject>({
              getDisabled: () => true,
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              getTitle: () => coreTextProvider.productCategory(),
              getValue: (orderItem: OrderItem) => orderItem.batch?.variant?.product?.category?.name,
              setValue: (orderItem: OrderItem, _): OrderItem => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.batch
                ?.variant
                ?.product
                ?.attributes
                ?.categoryId
            }),
            new DecimalFormField<OrderItem, OrderItemErrorsObject>({
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              getDisabled: () => true,
              getTitle: () => coreTextProvider.count(),
              getValue: (orderItem: OrderItem) => orderItem.count,
              setValue: (orderItem: OrderItem, _) => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.attributes
                ?.count
            }),
            new DecimalFormField<OrderItem, OrderItemErrorsObject>({
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              getDisabled: () => true,
              getTitle: () => coreTextProvider.price(),
              getValue: (orderItem: OrderItem) => orderItem.price,
              setValue: (orderItem: OrderItem, _) => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.attributes
                ?.price
            }),
            new StringFormField<OrderItem, OrderItemErrorsObject>({
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              getVisible: (orderItem: OrderItem) => orderItem.batch?.variant?.product?.hasBatches ?? false,
              getTitle: () => coreTextProvider.externalCode(),
              getValue: (orderItem: OrderItem) => orderItem.batch?.externalCode,
              setValue: (orderItem: OrderItem, _) => orderItem,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.batch
                ?.attributes
                ?.externalCode
            }),
            new ListFormField<OrderItem, OrderItemErrorsObject, VariantOption, VariantOptionErrorsObject>({
              isAddObjectVisible: false,
              getRemoveObjectVisible: () => false,
              getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
              displayType: ListFormFieldDisplayType.INLINE,
              getTitle: () => undefined,
              getValue: (orderItem: OrderItem) => orderItem.batch?.variant?.variantOptions,
              getErrors: (orderItemErrorsObject?: OrderItemErrorsObject) => orderItemErrorsObject
                ?.batch
                ?.variant
                ?.attributes
                ?.variantOptions,
              setValue: (orderItem: OrderItem, _): OrderItem => orderItem,
              getNestedObjectId: (variantOption: VariantOption) => variantOption.clientId!,
              getNestedObjectTitle: () => undefined,
              getNestedErrorsObject: (
                variantOption: VariantOption,
                orderItemErrorsObject?: OrderItemErrorsObject
              ) => {
                return orderItemErrorsObject?.batch?.variant?.variantOptions?.find(
                  (variantOptionErrorsObject: VariantOptionErrorsObject) => {
                    return variantOptionErrorsObject.clientId === variantOption.clientId
                  })
              },
              buildNewValue: (): VariantOption => ({
                clientId: uuidv4(),
                id: undefined,
                option: undefined,
                optionId: undefined,
                optionValueId: undefined,
                optionValue: undefined
              }),
              fields: [
                new StringFormField<VariantOption, VariantOptionErrorsObject>({
                  getDisabled: () => true,
                  getTitle: (variantOption: VariantOption) => variantOption.option?.name,
                  getValue: (variantOption: VariantOption) => variantOption.optionValue?.name,
                  setValue: (variantOption: VariantOption, _): VariantOption => variantOption,
                  getErrors: (variantOptionErrorsObject?: VariantOptionErrorsObject) => variantOptionErrorsObject
                    ?.attributes
                    ?.optionValue
                })
              ]
            })
          ]
        })
      ]
    })
  }

  private createOrderComment(): OrderComment {
    return {
      id: undefined,
      value: undefined
    }
  }

  private createOrderPayment(): OrderPayment {
    return {
      payment: undefined,
      id: undefined,
      method: undefined
    }
  }

  private formatUserFullName(user: User | null | undefined): string | null | undefined {
    const profile = user?.profile

    if (isBlank(profile)) return undefined

    return [
      profile?.lastName,
      profile?.firstName,
      profile?.middleName
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join(" ")
  }

  private formatRecipientFullName(recipient: OrderReceivingRecipient | null | undefined): string | null | undefined {
    if (isBlank(recipient)) return undefined

    return [
      recipient?.lastName,
      recipient?.firstName,
      recipient?.middleName
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join(" ")
  }

  private formatPhoneNumber(phoneNumber: PhoneNumber | null | undefined): string | null | undefined {
    return [
      phoneNumber?.country?.callingCode,
      phoneNumber?.value
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join("")
  }

  private formatedOrderReceivingValue(orderReceiving: OrderReceiving | null | undefined) {
    if (isBlank(orderReceiving)) return undefined

    const settlementValue = orderReceiving.settlement?.fullName
    const isPickUp = orderReceiving.method?.type === OrderReceivingMethodType.PICK_UP
    const placeOrAddressValue = isPickUp ? orderReceiving.place?.name : orderReceiving.address?.value
    const code = isPickUp && isPresent(orderReceiving.place?.code) ? ` [${orderReceiving.place?.code}]` : ""

    return [
      settlementValue,
      placeOrAddressValue
    ]
      .filter((value: string | null | undefined) => isPresent(value))
      .join(", ") + code
  }
}
