import isBlank from "./isBlank"

export default function transformDate({
  value
}: {
  value: string | undefined | null
}): Date | null | undefined {
  if (isBlank(value)) return null

  return new Date(Date.parse(value))
}
