import { Expose, Type } from "class-transformer"
import NetworkAttributeError from "../errors/NetworkAttributeError"
import OrderPaymentMethodErrorsObjectAttributes
  from "../../../domain/entities/order-payment-methods/OrderPaymentMethodErrorsObjectAttributes"

export default class NetworkOrderPaymentMethodErrorsObjectAttributes
  implements OrderPaymentMethodErrorsObjectAttributes {
  @Expose()
  @Type(() => NetworkAttributeError)
  readonly name: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isAvailable: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly position: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly isDefault: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly paymentProviderType: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly paymentProvider: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly type: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly id: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly orderReceivingMethods: NetworkAttributeError[] | null | undefined

  @Expose()
  @Type(() => NetworkAttributeError)
  readonly orderReceivingMethodIds: NetworkAttributeError[] | null | undefined
}
