import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import GetOptionUseCase from "../../../domain/use-cases/GetOptionUseCase"
import CreateOptionUseCase from "../../../domain/use-cases/CreateOptionUseCase"
import UpdateOptionUseCase from "../../../domain/use-cases/UpdateOptionUseCase"
import DestroyOptionUseCase from "../../../domain/use-cases/DestroyOptionUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import Option from "../../../../../core/domain/entities/options/Option"
import OptionError from "../../../../../core/domain/entities/options/OptionError"
import OptionErrorsObject from "../../../../../core/domain/entities/options/OptionErrorsObject"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import autoBind from "auto-bind"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import DisplayStyle from "../../../../../core/domain/entities/options/DisplayStyle"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import OptionValue from "../../../../../core/domain/entities/option-values/OptionValue"
import OptionValueErrorsObject from "../../../../../core/domain/entities/option-values/OptionValueErrorsObject"
import { v4 as uuidv4 } from "uuid"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"

export default class OptionViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getOptionUseCase: GetOptionUseCase
  private readonly createOptionUseCase: CreateOptionUseCase
  private readonly updateOptionUseCase: UpdateOptionUseCase
  private readonly destroyOptionUseCase: DestroyOptionUseCase
  private readonly optionId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    Option,
    OptionError,
    OptionErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getOptionUseCase: GetOptionUseCase
    readonly createOptionUseCase: CreateOptionUseCase
    readonly updateOptionUseCase: UpdateOptionUseCase
    readonly destroyOptionUseCase: DestroyOptionUseCase
    readonly optionId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getOptionUseCase = parameters.getOptionUseCase
    this.createOptionUseCase = parameters.createOptionUseCase
    this.updateOptionUseCase = parameters.updateOptionUseCase
    this.destroyOptionUseCase = parameters.destroyOptionUseCase
    this.optionId = parameters.optionId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Option,
    OptionError,
    OptionErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.optionId),
      buildObject: async() => ({}),
      getObjectUrl: (option) => {
        return this.coreUrlProvider.buildOptionUrl({
          id: option.id!
        })
      },
      loadObject: async() => {
        return await this.getOptionUseCase.call({ optionId: this.optionId! })
      },
      createObject: async({ object: option }) => {
        return await this.createOptionUseCase.call({ option })
      },
      updateObject: async({ object: option }) => {
        return await this.updateOptionUseCase.call({
          optionId: this.optionId!,
          option
        })
      },
      destroyObject: async() => {
        return await this.destroyOptionUseCase.call({ optionId: this.optionId! })
      },
      getErrorsObject: ({ error: optionError }) => optionError
        ?.errorsObject,
      formFields: [
        new StringFormField<Option, OptionErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (option: Option) => option.name,
          setValue: (option: Option, name: string) => ({
            ...option,
            name
          }),
          getErrors: (optionErrorsObject?: OptionErrorsObject) => optionErrorsObject
            ?.attributes?.name
        }),
        new StringFormField<Option, OptionErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (option: Option) => option.externalCode,
          setValue: (option: Option, externalCode: string) => ({
            ...option,
            externalCode
          }),
          getErrors: (optionErrorsObject?: OptionErrorsObject) => optionErrorsObject
            ?.attributes?.externalCode
        }),
        new NumberFormField<Option, OptionErrorsObject>({
          getTitle: () => coreTextProvider.position(),
          getValue: (option: Option) => option.position,
          setValue: (option: Option, position: number | null) => ({
            ...option,
            position
          }),
          getErrors: (optionErrorsObject?: OptionErrorsObject) => optionErrorsObject?.attributes?.position
        }),
        new SingleSelectFormField<Option, OptionErrorsObject, DisplayStyle>({
          isSearchBarVisible: false,
          getObjects: async() => {
            return {
              type: "success",
              data: {
                objects: Object.values(DisplayStyle),
                page: { hasMore: false }
              }
            }
          },
          getTitle: () => coreTextProvider.displayStyle(),
          getValue: (option: Option) => option.displayStyle,
          setValue: (option: Option, displayStyle: DisplayStyle | null) => ({
            ...option,
            displayStyle
          }),
          getErrors: (optionErrorsObject?: OptionErrorsObject) => optionErrorsObject?.attributes?.displayStyle,
          getOptionId: (displayStyle: DisplayStyle) => displayStyle.valueOf(),
          getOptionText: (displayStyle: DisplayStyle) => this.detectDisplayStyleDisplayName(displayStyle)
        }),
        new ListFormField<Option, OptionErrorsObject, OptionValue, OptionValueErrorsObject>({
          isSwitchPositionVisible: true,
          getTitle: () => coreTextProvider.optionValues(),
          getValue: (option: Option) => option.values,
          getErrors: (optionErrorsObject?: OptionErrorsObject) => optionErrorsObject?.attributes?.values,
          setValue: (option: Option, values: OptionValue[] | null): Option => ({
            ...option,
            values
          }),
          getNestedObjectId: (optionValue: OptionValue) => optionValue.clientId!,
          getNestedObjectTitle: () => coreTextProvider.optionValue(),
          getNestedErrorsObject: (optionValue: OptionValue, optionErrorsObject?: OptionErrorsObject) => {
            return optionErrorsObject?.values?.find(
              (optionValueErrorsObject: OptionValueErrorsObject) => {
                return optionValueErrorsObject.clientId === optionValue.clientId
              })
          },
          getPosition: (option: Option) => option.position!,
          setPosition: (option: Option, position: number): Option => {
            return { ...option, position }
          },
          buildNewValue: (option: Option) => this.createNewOptionValue(option),
          fields: [
            new StringFormField<OptionValue, OptionValueErrorsObject>({
              getTitle: () => coreTextProvider.name(),
              getValue: (optionValue: OptionValue) => optionValue.name,
              setValue: (optionValue: OptionValue, name: string) => {
                return {
                  ...optionValue,
                  name
                }
              },
              getErrors: (optionValueErrorsObject?: OptionValueErrorsObject) => optionValueErrorsObject
                ?.attributes?.name
            }),
            new StringFormField<OptionValue, OptionValueErrorsObject>({
              getDisabled: () => true,
              getTitle: () => coreTextProvider.externalCode(),
              getValue: (optionValue: OptionValue) => optionValue.externalCode,
              setValue: (optionValue: OptionValue, externalCode: string) => {
                return {
                  ...optionValue,
                  externalCode
                }
              },
              getErrors: (optionValueErrorsObject?: OptionValueErrorsObject) => optionValueErrorsObject
                ?.attributes?.externalCode
            })
          ]
        })
      ]
    })
  }

  private detectDisplayStyleDisplayName(displayStyle: DisplayStyle): string {
    // todo надо научиться локализовывать енамы на стороне View
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    switch (displayStyle) {
      case DisplayStyle.RADIO_BUTTONS:
        return coreTextProvider.displayStyleRadioButtons()
      case DisplayStyle.TAGS:
        return coreTextProvider.displayStyleTags()
    }
  }

  private createNewOptionValue(option: Option | null | undefined): OptionValue {
    const maxPosition = this.detectMaxPosition(option)
    const position = isPresent(maxPosition) ? maxPosition + 1 : 1

    return {
      clientId: uuidv4(),
      position: position
    }
  }

  private detectMaxPosition(option: Option | null | undefined): number | null | undefined {
    const positions = option
      ?.values
      ?.map(value => value.position)
      ?.filter((position): position is number => isPresent(position))

    if (isBlank(positions) || positions.length === 0) return null

    return Math.max(...positions)
  }
}
