enum OrderPaymentMethodType {
  OFFLINE = "offline",
  BANK_CARD = "bank_card",
  SBP = "sbp",
  YOO_MONEY = "yoo_money",
  SBER_PAY = "sber_pay",
  T_PAY = "t_pay"
}

export default OrderPaymentMethodType
