import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import Product from "../../../../../core/domain/entities/products/Product"
import ProductError from "../../../../../core/domain/entities/products/ProductError"
import ProductErrorsObject from "../../../../../core/domain/entities/products/ProductErrorsObject"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import SingleSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/SingleSelectFormField"
import ProductCategory from "../../../../../core/domain/entities/product-categories/ProductCategory"
import TextFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/TextFormField"
import DecimalFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/DecimalFormField"
import { Decimal } from "decimal.js"
import autoBind from "auto-bind"
import Option from "../../../../../core/domain/entities/options/Option"
import MultiSelectFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/MultiSelectFormField"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import OptionValue from "../../../../../core/domain/entities/option-values/OptionValue"
import { v4 as uuidv4 } from "uuid"
import ProductVariant from "../../../../../core/domain/entities/product-variants/ProductVariant"
import ProductVariantErrorsObject from "../../../../../core/domain/entities/product-variants/ProductVariantErrorsObject"
import VariantOption from "../../../../../core/domain/entities/variant-options/VariantOption"
import VariantOptionErrorsObject from "../../../../../core/domain/entities/variant-options/VariantOptionErrorsObject"
import VariantImage from "../../../../../core/domain/entities/variant-images/VariantImage"
import VariantImageErrorsObject from "../../../../../core/domain/entities/variant-images/VariantImageErrorsObject"
import ImageFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ImageFormField"
import Image from "../../../../../core/domain/entities/images/Image"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import BatchPlace from "../../../../../core/domain/entities/batch-paces/BatchPlace"
import BatchPlaceErrorsObject from "../../../../../core/domain/entities/batch-paces/BatchPlaceErrorsObject"
import Place from "../../../../../core/domain/entities/places/Place"
import GetPlacesUseCase from "../../../domain/use-cases/places/GetPlacesUseCase"
import {
  ListFormFieldDisplayType
} from "../../../../../../sqadmin/features/objects/presentation/components/form-field-by-type/list-form-field/ListFormFieldComponent"
import GetOptionsUseCase from "../../../domain/use-cases/options/GetOptionsUseCase"
import ProductProperty from "../../../../../core/domain/entities/product-properties/ProductProperty"
import ProductPropertyErrorsObject
  from "../../../../../core/domain/entities/product-properties/ProductPropertyErrorsObject"
import Property from "../../../../../core/domain/entities/properties/Property"
import PropertyValue from "../../../../../core/domain/entities/property-values/PropertyValue"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import FormFieldPlaceType
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/FormFieldPlaceType"
import BonusPointsRule from "../../../../../core/domain/entities/bonus-points-rules/BonusPointsRule"
import BonusPointsRuleErrorsObject
  from "../../../../../core/domain/entities/bonus-points-rules/BonusPointsRuleErrorsObject"
import GetBonusProgramLevelsUseCase
  from "../../../../bonus-program-levels-core/domain/use-cases/GetBonusProgramLevelsUseCase"
import ImageVariant from "../../../../../core/domain/entities/image-variants/ImageVariant"
import ImageOption from "../../../../../../sqadmin/core/presentation/components/image-picker/ImageOption"
import Batch from "../../../../../core/domain/entities/batch/Batch"
import BatchErrorsObject from "../../../../../core/domain/entities/batch/BatchErrorsObject"
import GetConfigurationUseCase from "../../../../configurations-core/domain/use-cases/GetConfigurationUseCase"
import { ProductEditor } from "./editors/ProductEditor"
import { ProductPropertyEditor } from "./editors/ProductPropertyEditor"
import Configuration from "../../../../../core/domain/entities/configurations/Configuration"
import { ProductVariantEditor } from "./editors/ProductVariantEditor"
import { ProductVariantProductImageEditor } from "./editors/ProductVariantProductImageEditor"
import { ProductVariantOptionValueEditor } from "./editors/ProductVariantOptionValueEditor"
import { ProductVariantBatchEditor } from "./editors/ProductVariantBatchEditor"
import { ProductVariantBatchPlaceEditor } from "./editors/ProductVariantBatchPlaceEditor"
import GetOptionValuesUseCase from "../../../../option-values-core/domain/use-cases/GetOptionValuesUseCase"
import GetPropertyValuesUseCase from "../../../../property-values-core/domain/use-cases/GetPropertyValuesUseCase"
import { BonusPointsRuleFields } from "../../../../../core/presentation/fields/BonusPointsRuleFields"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import GetPropertiesUseCase from "../../../../properties-core/domain/use-cases/GetPropertiesUseCase"
import GetProductCategoriesUseCase
  from "../../../../product-categories-core/domain/use-cases/product-categories/GetProductCategoriesUseCase"
import GetProductUseCase from "../../../../products-core/domain/use-cases/products/GetProductUseCase"
import CreateProductUseCase from "../../../../products-core/domain/use-cases/products/CreateProductUseCase"
import UpdateProductUseCase from "../../../../products-core/domain/use-cases/products/UpdateProductUseCase"
import DestroyProductUseCase from "../../../../products-core/domain/use-cases/products/DestroyProductUseCase"
import CreateProductImageUseCase from "../../../../products-core/domain/use-cases/images/CreateProductImageUseCase"

export default class ProductViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getProductUseCase: GetProductUseCase
  private readonly createProductUseCase: CreateProductUseCase
  private readonly updateProductUseCase: UpdateProductUseCase
  private readonly destroyProductUseCase: DestroyProductUseCase
  private readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
  private readonly getPlacesUseCase: GetPlacesUseCase
  private readonly getOptionsUseCase: GetOptionsUseCase
  private readonly getPropertiesUseCase: GetPropertiesUseCase
  private readonly createImageUseCase: CreateProductImageUseCase
  private readonly getBonusProgramLevelsUseCase: GetBonusProgramLevelsUseCase
  private readonly getConfigurationUseCase: GetConfigurationUseCase
  private readonly getOptionValuesUseCase: GetOptionValuesUseCase
  private readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
  private readonly productId?: number
  private readonly objectPresentationLogic: ObjectPresentationLogic<Product, ProductError, ProductErrorsObject>

  private configuration: Configuration | null | undefined
  private productEditor: ProductEditor

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getProductUseCase: GetProductUseCase
    readonly createProductUseCase: CreateProductUseCase
    readonly updateProductUseCase: UpdateProductUseCase
    readonly destroyProductUseCase: DestroyProductUseCase
    readonly getProductCategoriesUseCase: GetProductCategoriesUseCase
    readonly getPlacesUseCase: GetPlacesUseCase
    readonly getOptionsUseCase: GetOptionsUseCase
    readonly createImageUseCase: CreateProductImageUseCase
    readonly getPropertiesUseCase: GetPropertiesUseCase
    readonly getBonusProgramLevelsUseCase: GetBonusProgramLevelsUseCase
    readonly getConfigurationUseCase: GetConfigurationUseCase
    readonly getOptionValuesUseCase: GetOptionValuesUseCase
    readonly getPropertyValuesUseCase: GetPropertyValuesUseCase
    readonly productId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getProductUseCase = parameters.getProductUseCase
    this.createProductUseCase = parameters.createProductUseCase
    this.updateProductUseCase = parameters.updateProductUseCase
    this.destroyProductUseCase = parameters.destroyProductUseCase
    this.getProductCategoriesUseCase = parameters.getProductCategoriesUseCase
    this.getOptionsUseCase = parameters.getOptionsUseCase
    this.getPlacesUseCase = parameters.getPlacesUseCase
    this.createImageUseCase = parameters.createImageUseCase
    this.getPropertiesUseCase = parameters.getPropertiesUseCase
    this.getBonusProgramLevelsUseCase = parameters.getBonusProgramLevelsUseCase
    this.getConfigurationUseCase = parameters.getConfigurationUseCase
    this.getOptionValuesUseCase = parameters.getOptionValuesUseCase
    this.getPropertyValuesUseCase = parameters.getPropertyValuesUseCase
    this.productId = parameters.productId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    this.productEditor = new ProductEditor({ productId: this.productId })
    autoBind(this)
  }

  onObjectViewEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Product,
    ProductError,
    ProductErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()

    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.productId),
      getObjectUrl: (product) => {
        return this.coreUrlProvider.buildProductUrl({
          id: product.id!
        })
      },
      buildObject: async() => ({
        bonusProgramRule: undefined,
        hasBatches: false,
        mainVariant: this.createVariant(),
        inputMultiplier: undefined,
        isArchived: false,
        canBeOrderedWithBonusPoints: false
      }),
      loadObject: async() => {
        const configurationResult = await this.getConfigurationUseCase.call()

        if (configurationResult.type !== "success") return configurationResult

        this.configuration = configurationResult.data
        this.productEditor.setConfiguration(configurationResult.data)

        return await this.getProductUseCase.call({ productId: this.productId! })
      },
      createObject: async({ object: product }) => {
        return await this.createProductUseCase.call({ product })
      },
      updateObject: async({ object: product }) => {
        return await this.updateProductUseCase.call({
          productId: this.productId!,
          product
        })
      },
      destroyObject: async() => {
        return await this.destroyProductUseCase.call({ productId: this.productId! })
      },
      getErrorsObject: ({ error: productError }) => productError?.errorsObject,
      formFields: [
        new StringFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.isNameDisabled(),
          getTitle: () => coreTextProvider.name(),
          getValue: (product: Product) => product.name,
          setValue: (product: Product, name: string) => ({
            ...product,
            name
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.name
        }),
        new StringFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.isExternalCodeDisabled(),
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (product: Product) => product.externalCode,
          setValue: (product: Product, externalCode: string) => ({
            ...product,
            externalCode
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.externalCode
        }),
        new SingleSelectFormField<Product, ProductErrorsObject, ProductCategory>({
          getDisabled: () => this.productEditor.isCategoryIdDisabled(),
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getProductCategoriesUseCase.call({
              filter: { query },
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getTitle: () => coreTextProvider.productCategory(),
          getValue: (product: Product) => product.category,
          setValue: (product: Product, category: ProductCategory | null) => ({
            ...product,
            category,
            categoryId: category && category.id
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.categoryId,
          getOptionId: (category: ProductCategory) => category.id!.toString(),
          getOptionText: (category: ProductCategory) => category.name
        }),
        new TextFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.isDescriptionDisabled(),
          getTitle: () => coreTextProvider.description(),
          getValue: (product: Product) => product.description,
          setValue: (product: Product, description: string) => ({
            ...product,
            description
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.description
        }),
        new DecimalFormField<Product, ProductErrorsObject>({
          getDisabled: (product: Product) => this.createBatchEditor().isPriceDisabled(product.id),
          getTitle: () => coreTextProvider.price(),
          getValue: (product: Product) => product.mainVariant?.mainBatch?.price,
          setValue: (product: Product, price: Decimal | null | undefined) => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                price
              }
            }
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.mainBatch
            ?.attributes
            ?.price
        }),
        new DecimalFormField<Product, ProductErrorsObject>({
          getDisabled: (product: Product) => {
            return this.createBatchEditor().isOriginalPriceDisabled(product.id)
          },
          getTitle: () => coreTextProvider.originalPrice(),
          getValue: (product: Product) => product.mainVariant?.mainBatch?.originalPrice,
          setValue: (product: Product, originalPrice: Decimal | null | undefined) => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                originalPrice
              }
            }
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.mainBatch
            ?.attributes
            ?.originalPrice
        }),
        new DecimalFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.isInputMultiplierDisabled(),
          getTitle: () => coreTextProvider.inputMultiplier(),
          getValue: (product: Product) => product.inputMultiplier,
          setValue: (product: Product, value: Decimal | null | undefined) => ({
            ...product,
            inputMultiplier: value
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.attributes
            ?.inputMultiplier
        }),
        new BooleanFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.canBeOrderedWithBonusPointsDisabled(),
          getValue: (product: Product) => product.canBeOrderedWithBonusPoints,
          setValue: (product: Product, canBeOrderedWithBonusPoints: boolean) => ({
            ...product,
            canBeOrderedWithBonusPoints
          }),
          getText: () => coreTextProvider.canBeOrderedWithBonusPoints(),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.attributes
            ?.canBeOrderedWithBonusPoints
        }),
        new NumberFormField<Product, ProductErrorsObject>({
          getVisible: (product: Product) => product.canBeOrderedWithBonusPoints ?? false,
          getDisabled: (product: Product) => {
            return this.createBatchEditor().isBonusPointsPriceDisabled(product.id)
          },
          getTitle: () => coreTextProvider.bonusPointsPrice(),
          getValue: (product: Product) => product.mainVariant?.mainBatch?.bonusPointsPrice,
          setValue: (product: Product, bonusPointsPrice: number | null | undefined) => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                bonusPointsPrice
              }
            }
          }),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.mainBatch
            ?.attributes
            ?.bonusPointsPrice
        }),
        new ListFormField<Product, ProductErrorsObject, VariantImage, VariantImageErrorsObject>({
          isAddObjectVisible: this.createImageEditor().canCreate(),
          getRemoveObjectVisible: (variantImage: VariantImage) => this.createImageEditor().canDestroy(variantImage.id),
          addObjectButtonName: coreTextProvider.add(),
          isSwitchPositionVisible: true,
          getTitle: () => coreTextProvider.mainVariantImages(),
          getValue: (product: Product) => product.mainVariant?.variantImages,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.attributes
            ?.variantImages,
          setValue: (product: Product, variantImages: VariantImage[] | null): Product => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              variantImages
            }
          }),
          getNestedObjectId: (variantImage: VariantImage) => variantImage.clientId!,
          getNestedObjectTitle: () => coreTextProvider.image(),
          getNestedErrorsObject: (variantImage: VariantImage, productErrorsObject?: ProductErrorsObject) => {
            return productErrorsObject?.mainVariant?.variantImages?.find(
              (variantImageErrorsObject: VariantImageErrorsObject) => {
                return variantImageErrorsObject.clientId === variantImage.clientId
              })
          },
          hasNestedErrorsObjects: (productErrorsObject?: ProductErrorsObject) => {
            return isPresent(productErrorsObject?.mainVariant?.variantImages)
          },
          getPosition: (variantImage: VariantImage) => variantImage.position!,
          setPosition: (variantImage: VariantImage, position: number): VariantImage => {
            return { ...variantImage, position }
          },
          buildNewValue: (product: Product) => this.createVariantImage(product.mainVariant),
          fields: this.createVariantImagesFields((variantImage: VariantImage) => {
            this.objectPresentationLogic.changeObject((product: Product) => {
              return this.updateMainVariant(product, variantImage)
            })
          })
        }),
        new ListFormField<Product, ProductErrorsObject, ProductProperty, ProductPropertyErrorsObject>({
          isAddObjectVisible: this.createProductPropertyEditor().canCreate(),
          getRemoveObjectVisible: (productProperty: ProductProperty) => {
            return this.createProductPropertyEditor().canDestroy(productProperty)
          },
          getTitle: () => coreTextProvider.properties(),
          getValue: (product: Product) => product.productProperties,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.attributes
            ?.productProperties,
          setValue: (product: Product, productProperties: ProductProperty[] | null): Product => ({
            ...product,
            productProperties
          }),
          getNestedObjectId: (productProperty: ProductProperty) => productProperty.clientId!,
          getNestedObjectTitle: (_, nestedObjectIndex) => {
            return coreTextProvider.propertyWithNumber({ number: nestedObjectIndex + 1 })
          },
          getNestedErrorsObject: (
            productProperty: ProductProperty,
            productErrorsObject?: ProductErrorsObject
          ) => {
            return productErrorsObject?.productProperties?.find(
              (productPropertyErrorsObject: ProductPropertyErrorsObject) => {
                return productPropertyErrorsObject.clientId === productProperty.clientId
              })
          },
          hasNestedErrorsObjects: (productErrorsObject?: ProductErrorsObject) => {
            return isPresent(productErrorsObject?.productProperties)
          },
          buildNewValue: () => ({ clientId: uuidv4() }),
          fields: [
            new SingleSelectFormField<ProductProperty, ProductPropertyErrorsObject, Property>({
              getDisabled: (productProperty: ProductProperty) => {
                return this.createProductPropertyEditor().isPropertyDisabled(productProperty)
              },
              getObjects: async({
                query,
                lastObject
              }) => {
                return await this.getPropertiesUseCase.call({
                  filter: { query },
                  pagination: { id: lastObject?.id ?? undefined }
                })
              },
              getValue: (productProperty: ProductProperty) => productProperty.property,
              setValue: (productProperty: ProductProperty, property: Property | null) => ({
                ...productProperty,
                property,
                propertyId: property?.id,
                propertyValueId: undefined,
                propertyValue: undefined
              }),
              getErrors: (productPropertyErrorsObject?: ProductPropertyErrorsObject) => productPropertyErrorsObject
                ?.attributes
                ?.propertyId,
              getOptionId: (property: Property) => property.id!.toString(),
              getOptionText: (property: Property) => property.name
            }),
            new SingleSelectFormField<ProductProperty, ProductPropertyErrorsObject, PropertyValue>({
              getDisabled: (productProperty: ProductProperty) => {
                return this.createProductPropertyEditor()
                  .isPropertyValueDisabled(productProperty) || isBlank(productProperty.propertyId)
              },
              getObjects: async({ parentObject, query }) => await this.getPropertyValuesUseCase.call({
                propertyId: parentObject!.propertyId!,
                query: query
              }),
              getTitle: () => coreTextProvider.propertyValue(),
              getValue: (productProperty: ProductProperty) => productProperty.propertyValue,
              setValue: (productProperty: ProductProperty, propertyValue: PropertyValue | null) => ({
                ...productProperty,
                propertyValue,
                propertyValueId: propertyValue?.id
              }),
              getErrors: (productPropertyErrorsObject?: ProductPropertyErrorsObject) => productPropertyErrorsObject
                ?.attributes
                ?.propertyValueId,
              getOptionId: (propertyValue: PropertyValue) => propertyValue.id!.toString(),
              getOptionText: (propertyValue: PropertyValue) => propertyValue.name
            })
          ]
        }),
        new ListFormField<Product, ProductErrorsObject, BonusPointsRule, BonusPointsRuleErrorsObject>({
          getTitle: () => coreTextProvider.bonusPointsAccrual(),
          getValue: (product: Product) => product.bonusProgramRule?.bonusPointsRules,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.attributes
            ?.bonusProgramRule,
          setValue: (product: Product, values: BonusPointsRule[] | null): Product => ({
            ...product,
            bonusProgramRule: {
              ...product.bonusProgramRule,
              id: product.bonusProgramRule && product.bonusProgramRule.id,
              bonusPointsRules: values
            }
          }),
          getNestedObjectId: (bonusPointsRule: BonusPointsRule) => bonusPointsRule.clientId!,
          getNestedObjectTitle: (_, index) => coreTextProvider.bonusPointsRule({ number: index + 1 }),
          getNestedErrorsObject: (
            bonusPointsRule: BonusPointsRule,
            productErrorsObject?: ProductErrorsObject
          ) => {
            return productErrorsObject?.bonusProgramRule?.bonusPointsRules?.find(
              (bonusPointsRuleErrorsObject: BonusPointsRuleErrorsObject) => {
                return bonusPointsRuleErrorsObject.clientId === bonusPointsRule.clientId
              })
          },
          buildNewValue: () => ({
            clientId: uuidv4(),
            bonusProgramLevelId: undefined,
            bonusProgramLevel: undefined,
            id: undefined,
            calculator: undefined
          }),
          fields: new BonusPointsRuleFields({
            coreI18n: this.coreI18n,
            getBonusProgramLevels: async({ query, lastObjectId }) => await this.getBonusProgramLevelsUseCase.call({
              filter: { query },
              pagination: { id: lastObjectId }
            })
          }).createFields()
        }),
        new BooleanFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.hasBatchesDisabled(),
          getValue: (product: Product) => product.hasBatches,
          setValue: (product: Product, hasBatches: boolean) => ({
            ...product,
            hasBatches,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                batchPlaces: undefined
              },
              batches: undefined
            },
            variants: product.variants?.map((variant: ProductVariant) => ({
              ...variant,
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                batchPlaces: undefined
              },
              batches: undefined
            }))
          }),
          getText: () => coreTextProvider.hasBatches(),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.hasBatches
        }),
        new ListFormField<Product, ProductErrorsObject, BatchPlace, BatchPlaceErrorsObject>({
          isAddObjectVisible: this.createBatchPlaceEditor().canCreate(),
          getRemoveObjectVisible: (batchPlace: BatchPlace) => this.createBatchPlaceEditor().canDestroy(batchPlace.id),
          getVisible: (product: Product) => {
            const isEmptyOptions = (product.options?.length ?? 0) === 0
            const hasBatches = product?.hasBatches ?? false
            return isEmptyOptions && !hasBatches
          },
          addObjectButtonName: coreTextProvider.add(),
          getTitle: () => coreTextProvider.places(),
          getValue: (product: Product) => product.mainVariant?.mainBatch?.batchPlaces,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.mainBatch
            ?.attributes
            ?.batchPlaces,
          setValue: (product: Product, batchPlace: BatchPlace[] | null): Product => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              mainBatch: {
                ...product.mainVariant?.mainBatch ?? this.createBatch(),
                batchPlaces: batchPlace
              }
            }
          }),
          getNestedObjectId: (batchPlace: BatchPlace) => batchPlace.clientId!,
          getNestedObjectTitle: () => coreTextProvider.place(),
          getNestedErrorsObject: (batchPlace: BatchPlace, productErrorsObject?: ProductErrorsObject) => {
            return productErrorsObject?.mainVariant?.mainBatch?.batchPlaces?.find(
              (batchPlaceErrorsObject: BatchPlaceErrorsObject) => {
                return batchPlaceErrorsObject.clientId === batchPlace.clientId
              })
          },
          hasNestedErrorsObjects: (productErrorsObject?: ProductErrorsObject) => {
            return isPresent(productErrorsObject?.mainVariant?.mainBatch?.batchPlaces)
          },
          buildNewValue: () => ({
            clientId: uuidv4(),
            id: undefined,
            placeId: undefined,
            place: undefined,
            isAvailable: true
          }),
          fields: this.createBatchPlaceFields()
        }),
        new ListFormField<Product, ProductErrorsObject, Batch, BatchErrorsObject>({
          isAddObjectVisible: this.createBatchEditor().canCreate(),
          getRemoveObjectVisible: (batch: Batch) => this.createBatchEditor().canDestroy(batch.id),
          getVisible: (product: Product) => {
            const isEmptyOptions = (product.options?.length ?? 0) === 0
            const hasBatches = product?.hasBatches ?? false
            return isEmptyOptions && hasBatches
          },
          addObjectButtonName: coreTextProvider.add(),
          isEditVisible: true,
          getTitle: () => coreTextProvider.batches(),
          getValue: (product: Product) => product.mainVariant?.batches,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject
            ?.mainVariant
            ?.attributes
            ?.batches,
          setValue: (product: Product, batches: Batch[] | null): Product => ({
            ...product,
            mainVariant: {
              ...product.mainVariant ?? this.createVariant(),
              batches: batches
            }
          }),
          getNestedObjectId: (batch: Batch) => batch.clientId!,
          getNestedObjectTitle: (_, index) => coreTextProvider.batch({ number: index + 1 }),
          getNestedErrorsObject: (batch: Batch, productErrorsObject?: ProductErrorsObject) => {
            return productErrorsObject?.mainVariant?.batches?.find(
              (batchErrorsObject: BatchErrorsObject) => {
                return batchErrorsObject.clientId === batch.clientId
              })
          },
          hasNestedErrorsObjects: (productErrorsObject?: ProductErrorsObject) => {
            return isPresent(productErrorsObject?.mainVariant?.batches)
          },
          buildNewValue: (): Batch => this.createBatch(),
          fields: [
            this.createBatchPreview(),
            this.createBatchCode(),
            this.createBatchPrice(),
            this.createBatchOriginalPrice(),
            this.createBatchBonusPointsPrice(),
            this.createBatchPlaces()
          ]
        }),
        new MultiSelectFormField<Product, ProductErrorsObject, Option>({
          getDisabled: () => this.productEditor.isOptionIdsDisabled(),
          getObjects: async({
            query,
            lastObject
          }) => {
            return await this.getOptionsUseCase.call({
              filter: { query },
              pagination: { id: lastObject?.id ?? undefined }
            })
          },
          getTitle: () => coreTextProvider.options(),
          getValue: (product: Product) => product.options,
          setValue: (product: Product, options: Option[] | null) => {
            return {
              ...product,
              options,
              optionIds: options?.map((option) => option.id!),
              variants: undefined,
              mainVariant: {
                ...product.mainVariant ?? this.createVariant(),
                mainBatch: {
                  ...product.mainVariant?.mainBatch ?? this.createBatch(),
                  batches: undefined,
                  batchPlaces: undefined
                },
                batches: undefined
              }
            }
          },
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.optionIds,
          getOptionId: (option: Option) => option.id!.toString(),
          getOptionText: (option: Option) => option.name
        }),
        new ListFormField<Product, ProductErrorsObject, ProductVariant, ProductVariantErrorsObject>({
          isAddObjectVisible: this.createProductVariantEditor().canCreate(),
          addObjectButtonName: coreTextProvider.add(),
          getRemoveObjectVisible: (productVariant: ProductVariant) => {
            return this.createProductVariantEditor().canDestroy(productVariant.id)
          },
          getVisible: (product: Product) => (product.options?.length ?? 0) > 0,
          isEditVisible: true,
          getTitle: () => coreTextProvider.variants(),
          getValue: (product: Product) => product.variants,
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.variants,
          setValue: (product: Product, variants: ProductVariant[] | null): Product => ({
            ...product,
            variants
          }),
          getNestedObjectId: (variant: ProductVariant) => variant.clientId!,
          getNestedObjectTitle: (_, index) => coreTextProvider.variant({ number: index + 1 }),
          getNestedErrorsObject: (productVariant: ProductVariant, productErrorsObject?: ProductErrorsObject) => {
            return productErrorsObject?.variants?.find(
              (productVariantErrorsObject: ProductVariantErrorsObject) => {
                return productVariantErrorsObject.clientId === productVariant.clientId
              })
          },
          hasNestedErrorsObjects: (productErrorsObject?: ProductErrorsObject) => {
            return isPresent(productErrorsObject?.variants)
          },
          buildNewValue: (product: Product) => ({
            clientId: uuidv4(),
            variantOptions: product.options?.map(option => {
              return {
                optionId: option.id,
                option: option,
                clientId: uuidv4()
              }
            }),
            mainBatch: this.createBatch(),
            product: undefined,
            batches: undefined
          }),
          fields: [
            this.createVariantPreview(),
            this.createVariantExternalCode(),
            this.createVariantOption(),
            this.createVariantPrice(),
            this.createVariantOriginalPrice(),
            this.createVariantBonusPointsPrice(),
            this.createVariantImages(),
            this.createVariantPlaces(),
            this.createBatches()
          ]
        }),
        new BooleanFormField<Product, ProductErrorsObject>({
          getDisabled: () => this.productEditor.isArchivedDisabled(),
          getVisible: () => isPresent(this.productId),
          getValue: (product: Product) => product.isArchived,
          setValue: (product: Product, isArchived: boolean) => ({
            ...product,
            isArchived
          }),
          getText: () => coreTextProvider.isArchived(),
          getErrors: (productErrorsObject?: ProductErrorsObject) => productErrorsObject?.attributes?.isArchived
        })
      ]
    })
  }

  private updateMainVariant(product: Product, variantImage: VariantImage): Product {
    const updatedMainVariant = this.resetIsMainOtherVariantImage(product.mainVariant, variantImage)
    return {
      ...product,
      mainVariant: updatedMainVariant
    }
  }

  private updateVariants(product: Product, variantImage: VariantImage): Product {
    const updatedVariants = product.variants?.map(variant => {
      return this.updateVariantIfNeed(variant, variantImage)
    })

    return {
      ...product,
      variants: updatedVariants
    }
  }

  private updateVariantIfNeed(
    variant: ProductVariant,
    variantImage: VariantImage
  ): ProductVariant {
    const variantContainsVariantImage = this.checkVariantImagesContainsVariantImage(
      variant.variantImages ?? [],
      variantImage
    )

    if (variantContainsVariantImage) {
      return this.resetIsMainOtherVariantImage(variant, variantImage)
    } else {
      return variant
    }
  }

  private resetIsMainOtherVariantImage(
    variant: ProductVariant | null | undefined,
    variantImage: VariantImage
  ): ProductVariant {
    const updatedVariantImages = variant?.variantImages?.map(
      variantImageFormVariant => {
        const isMain = variantImageFormVariant.clientId === variantImage.clientId ?
          variantImageFormVariant.isMain : false

        return {
          ...variantImageFormVariant,
          isMain
        }
      })

    return {
      ...variant ?? this.createVariant(),
      variantImages: updatedVariantImages
    }
  }

  private checkVariantImagesContainsVariantImage(
    variantImages: VariantImage[],
    variantImage: VariantImage
  ): boolean {
    return variantImages.find(variantImageFormVariant => {
      return variantImageFormVariant.clientId === variantImage.clientId
    }) !== null
  }

  private createVariant(): ProductVariant {
    return {
      product: undefined,
      mainBatch: this.createBatch(),
      batches: undefined
    }
  }

  private createBatch(): Batch {
    return {
      id: undefined,
      externalCode: undefined,
      price: undefined,
      originalPrice: undefined,
      clientId: uuidv4(),
      batchPlaces: undefined,
      variant: undefined,
      bonusPointsPrice: undefined
    }
  }

  private createVariantImage(variant: ProductVariant | null | undefined): VariantImage {
    const isMain = variant?.variantImages?.some(variantImage => variantImage.isMain)
    const maxPosition = this.detectMaxPosition(variant)
    const position = isPresent(maxPosition) ? maxPosition + 1 : 1

    return {
      clientId: uuidv4(),
      id: undefined,
      imageId: undefined,
      position: position,
      image: undefined,
      isMain: !isMain
    }
  }

  private createImage(): Image {
    return {
      id: undefined,
      listImageVariant: undefined,
      pagerImageVariant: undefined,
      sliderImageVariant: undefined,
      detailsImageVariant: undefined,
      fullScreenImageVariant: undefined,
      clientId: uuidv4(),
      attachmentExists: false,
      externalCode: undefined
    }
  }

  private detectMaxPosition(variant: ProductVariant | null | undefined): number | null | undefined {
    const positions = variant
      ?.variantImages
      ?.map(variantImage => variantImage.position)
      ?.filter((position): position is number => isPresent(position))

    if (isBlank(positions) || positions.length === 0) return null

    return Math.max(...positions)
  }

  private createVariantPreview() {
    return new StringFormField<ProductVariant, ProductVariantErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.MAIN,
      getDisabled: () => true,
      getValue: (productVariant: ProductVariant) => productVariant.variantOptions
        ?.map((value) => value.optionValue?.name)
        .filter(value => isPresent(value))
        .join(", "),
      setValue: (productVariant: ProductVariant) => ({ ...productVariant }),
      getErrors: () => undefined
    })
  }

  private createVariantExternalCode() {
    return new StringFormField<ProductVariant, ProductVariantErrorsObject>({
      getDisabled: (productVariant: ProductVariant) => {
        return this.createProductVariantEditor().isExternalCodeDisabled(productVariant.id)
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().externalCode(),
      getValue: (productVariant: ProductVariant) => productVariant.externalCode,
      setValue: (productVariant: ProductVariant, externalCode: string) => ({
        ...productVariant,
        externalCode
      }),
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return productVariantErrorsObject?.attributes?.externalCode
      }
    })
  }

  private createVariantOption() {
    return new ListFormField<ProductVariant, ProductVariantErrorsObject, VariantOption, VariantOptionErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      displayType: ListFormFieldDisplayType.INLINE,
      getValue: (variant: ProductVariant) => variant.variantOptions,
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.attributes
        ?.variantOptions,
      setValue: (variant: ProductVariant, variantOptions: VariantOption[] | null): ProductVariant => ({
        ...variant,
        variantOptions
      }),
      getNestedObjectId: (variantOption: VariantOption) => variantOption.clientId!,
      getNestedObjectTitle: () => undefined,
      getNestedErrorsObject: (
        variantOption: VariantOption,
        productVariantErrorsObject?: ProductVariantErrorsObject
      ) => {
        return productVariantErrorsObject?.variantOptions?.find(
          (variantOptionErrorsObject: VariantOptionErrorsObject) => {
            return variantOptionErrorsObject.clientId === variantOption.clientId
          })
      },
      hasNestedErrorsObjects: (productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return isPresent(productVariantErrorsObject?.variantOptions)
      },
      buildNewValue: () => ({ clientId: uuidv4() }),
      getRemoveObjectVisible: () => false,
      isAddObjectVisible: false,
      fields: [
        new SingleSelectFormField<VariantOption, VariantOptionErrorsObject, OptionValue>({
          getDisabled: (variantOption: VariantOption) => {
            return this.createOptionValueEditor().isValueDisabled(variantOption.id)
          },
          getObjects: async({ parentObject, query }) => await this.getOptionValuesUseCase.call({
            optionId: parentObject.optionId!,
            query
          }),
          getTitle: (variantOption: VariantOption) => variantOption.option?.name,
          getValue: (variantOption: VariantOption) => variantOption.optionValue,
          setValue: (variantOption: VariantOption, optionValue: OptionValue | null) => ({
            ...variantOption,
            optionValue,
            optionValueId: optionValue?.id
          }),
          getErrors: (variantOptionErrorsObject?: VariantOptionErrorsObject) => variantOptionErrorsObject
            ?.attributes
            ?.optionValueId,
          getOptionId: (optionValue: OptionValue) => optionValue.id!.toString(),
          getOptionText: (optionValue: OptionValue) => optionValue.name
        })
      ]
    })
  }

  private createVariantPrice() {
    return new DecimalFormField<ProductVariant, ProductVariantErrorsObject>({
      getDisabled: (productVariant: ProductVariant) => {
        return this.createBatchEditor().isPriceDisabled(productVariant.id)
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().price(),
      getValue: (productVariant: ProductVariant) => productVariant.mainBatch?.price,
      setValue: (productVariant: ProductVariant, price: Decimal | null | undefined) => ({
        ...productVariant,
        mainBatch: {
          ...productVariant?.mainBatch ?? this.createBatch(),
          price
        }
      }),
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.mainBatch
        ?.attributes
        ?.price
    })
  }

  private createVariantOriginalPrice() {
    return new DecimalFormField<ProductVariant, ProductVariantErrorsObject>({
      getDisabled: (productVariant: ProductVariant) => {
        return this.createBatchEditor().isOriginalPriceDisabled(productVariant.id)
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().originalPrice(),
      getValue: (productVariant: ProductVariant) => productVariant.mainBatch?.originalPrice,
      setValue: (productVariant: ProductVariant, originalPrice: Decimal | null | undefined) => ({
        ...productVariant,
        mainBatch: {
          ...productVariant?.mainBatch ?? this.createBatch(),
          originalPrice
        }
      }),
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.mainBatch
        ?.attributes
        ?.originalPrice
    })
  }

  private createVariantBonusPointsPrice() {
    return new NumberFormField<ProductVariant, ProductVariantErrorsObject>({
      getVisible: () => this.objectPresentationLogic.getObject()?.canBeOrderedWithBonusPoints ?? false,
      getDisabled: (productVariant: ProductVariant) => {
        return this.createBatchEditor().isBonusPointsPriceDisabled(productVariant.id)
      },
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().bonusPointsPrice(),
      getValue: (productVariant: ProductVariant) => productVariant.mainBatch?.bonusPointsPrice,
      setValue: (productVariant: ProductVariant, bonusPointsPrice: number | null | undefined) => ({
        ...productVariant,
        mainBatch: {
          ...productVariant?.mainBatch ?? this.createBatch(),
          bonusPointsPrice
        }
      }),
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.mainBatch
        ?.attributes
        ?.bonusPointsPrice
    })
  }

  private createVariantPlaces() {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return new ListFormField<ProductVariant, ProductVariantErrorsObject, BatchPlace, BatchPlaceErrorsObject>({
      isAddObjectVisible: this.createBatchPlaceEditor().canCreate(),
      getRemoveObjectVisible: (batchPlace: BatchPlace) => this.createBatchPlaceEditor().canDestroy(batchPlace.id),
      getVisible: () => !(this.objectPresentationLogic.getObject()?.hasBatches ?? false),
      addObjectButtonName: coreTextProvider.add(),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => coreTextProvider.places(),
      getValue: (productVariant: ProductVariant) => productVariant.mainBatch?.batchPlaces,
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.mainBatch
        ?.attributes
        ?.batchPlaces,
      setValue: (productVariant: ProductVariant, batchPlaces: BatchPlace[] | null): ProductVariant => ({
        ...productVariant,
        mainBatch: {
          ...productVariant?.mainBatch ?? this.createBatch(),
          batchPlaces: batchPlaces
        }
      }),
      getNestedObjectId: (batchPlace: BatchPlace) => batchPlace.clientId!,
      getNestedObjectTitle: () => coreTextProvider.place(),
      getNestedErrorsObject: (
        batchPlace: BatchPlace,
        productVariantErrorsObject?: ProductVariantErrorsObject
      ) => {
        return productVariantErrorsObject?.mainBatch?.batchPlaces?.find(
          (batchPlaceErrorsObject: BatchPlaceErrorsObject) => {
            return batchPlaceErrorsObject.clientId === batchPlace.clientId
          })
      },
      hasNestedErrorsObjects: (productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return isPresent(productVariantErrorsObject?.mainBatch?.batchPlaces)
      },
      buildNewValue: () => ({
        clientId: uuidv4(),
        id: undefined,
        placeId: undefined,
        place: undefined,
        isAvailable: true
      }),
      fields: this.createBatchPlaceFields()
    })
  }

  private createVariantImages() {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return new ListFormField<ProductVariant, ProductVariantErrorsObject, VariantImage, VariantImageErrorsObject>({
      isAddObjectVisible: this.createImageEditor().canCreate(),
      getRemoveObjectVisible: (variantImage: VariantImage) => this.createImageEditor().canDestroy(variantImage.id),
      addObjectButtonName: coreTextProvider.add(),
      isSwitchPositionVisible: true,
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => coreTextProvider.variantImages(),
      getValue: (productVariant: ProductVariant) => productVariant.variantImages,
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.attributes
        ?.variantImages,
      setValue: (productVariant: ProductVariant, variantImages: VariantImage[] | null): ProductVariant => ({
        ...productVariant,
        variantImages
      }),
      getNestedObjectId: (variantImage: VariantImage) => variantImage.clientId!,
      getNestedObjectTitle: () => coreTextProvider.image(),
      getNestedErrorsObject: (
        variantImage: VariantImage,
        productVariantErrorsObject?: ProductVariantErrorsObject
      ) => {
        return productVariantErrorsObject?.variantImages?.find(
          (variantImageErrorsObject: VariantImageErrorsObject) => {
            return variantImageErrorsObject.clientId === variantImage.clientId
          })
      },
      hasNestedErrorsObjects: (productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return isPresent(productVariantErrorsObject?.variantImages)
      },
      getPosition: (variantImage: VariantImage) => variantImage.position!,
      setPosition: (variantImage: VariantImage, position: number): VariantImage => {
        return { ...variantImage, position }
      },
      buildNewValue: (variant: ProductVariant) => this.createVariantImage(variant),
      fields: this.createVariantImagesFields((variantImage: VariantImage) => {
        this.objectPresentationLogic.changeObject((product: Product) => {
          return this.updateVariants(product, variantImage)
        })
      })
    })
  }

  private createImageOptions(image: Image) {
    const options: ImageOption[] = []
    const textProvider = this.coreI18n.getTextProvider()

    if (isPresent(image.listImageVariant)) {
      options.push(this.createImageOption(image.listImageVariant, textProvider.imageViewTypeList()))
    }

    if (isPresent(image.detailsImageVariant)) {
      options.push(this.createImageOption(image.detailsImageVariant, textProvider.imageViewTypeDetails()))
    }

    if (isPresent(image.fullScreenImageVariant)) {
      options.push(this.createImageOption(image.fullScreenImageVariant, textProvider.imageViewTypeFullScreen()))
    }

    return options
  }

  private createImageOption(imageVariant: ImageVariant, name: string) {
    return {
      url: imageVariant?.url,
      width: imageVariant?.dimensions?.width,
      height: imageVariant?.dimensions?.height,
      /*когда появится от сервера превью заменить*/
      originalSizeUrl: imageVariant?.url,
      originalWidth: imageVariant?.dimensions?.width,
      originalHeight: imageVariant?.dimensions?.height,
      name: name
    }
  }

  private createBatchPreview() {
    return new StringFormField<Batch, BatchErrorsObject>({
      getPlaceType: () => FormFieldPlaceType.MAIN,
      getDisabled: () => true,
      getValue: (batch: Batch) => batch.externalCode,
      setValue: (batch: Batch) => ({ ...batch }),
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject?.attributes?.externalCode
    })
  }

  private createBatchCode() {
    return new StringFormField<Batch, BatchErrorsObject>({
      getDisabled: (batch: Batch) => this.createBatchEditor().isExternalCodeDisabled(batch.id),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().externalCode(),
      getValue: (batch: Batch) => batch.externalCode,
      setValue: (batch: Batch, externalCode: string) => ({
        ...batch,
        externalCode: externalCode
      }),
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject?.attributes?.externalCode
    })
  }

  private createBatchPrice() {
    return new DecimalFormField<Batch, BatchErrorsObject>({
      getDisabled: (batch: Batch) => this.createBatchEditor().isPriceDisabled(batch.id),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().price(),
      getValue: (batch: Batch) => batch.price,
      setValue: (batch: Batch, price: Decimal | null | undefined) => ({
        ...batch,
        price
      }),
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject
        ?.attributes
        ?.price
    })
  }

  private createBatchOriginalPrice() {
    return new DecimalFormField<Batch, BatchErrorsObject>({
      getDisabled: (batch: Batch) => this.createBatchEditor().isOriginalPriceDisabled(batch.id),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().originalPrice(),
      getValue: (batch: Batch) => batch.originalPrice,
      setValue: (batch: Batch, originalPrice: Decimal | null | undefined) => ({
        ...batch,
        originalPrice
      }),
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject
        ?.attributes
        ?.originalPrice
    })
  }

  private createBatchBonusPointsPrice() {
    return new NumberFormField<Batch, BatchErrorsObject>({
      getVisible: () => this.objectPresentationLogic.getObject()?.canBeOrderedWithBonusPoints ?? false,
      getDisabled: (batch: Batch) => this.createBatchEditor().isBonusPointsPriceDisabled(batch.id),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => this.coreI18n.getTextProvider().bonusPointsPrice(),
      getValue: (batch: Batch) => batch.bonusPointsPrice,
      setValue: (batch: Batch, bonusPointsPrice: number | null | undefined) => ({
        ...batch,
        bonusPointsPrice
      }),
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject
        ?.attributes
        ?.bonusPointsPrice
    })
  }

  private createBatchPlaces() {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return new ListFormField<Batch, BatchErrorsObject, BatchPlace, BatchPlaceErrorsObject>({
      isAddObjectVisible: this.createBatchPlaceEditor().canCreate(),
      getRemoveObjectVisible: (batchPlace: BatchPlace) => this.createBatchPlaceEditor().canDestroy(batchPlace.id),
      addObjectButtonName: coreTextProvider.add(),
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      getTitle: () => coreTextProvider.places(),
      getValue: (batch: Batch) => batch?.batchPlaces,
      getErrors: (batchErrorsObject?: BatchErrorsObject) => batchErrorsObject
        ?.attributes
        ?.batchPlaces,
      setValue: (batch: Batch, batchPlaces: BatchPlace[] | null): Batch => ({
        ...batch,
        batchPlaces: batchPlaces
      }),
      getNestedObjectId: (batchPlace: BatchPlace) => batchPlace.clientId!,
      getNestedObjectTitle: () => coreTextProvider.place(),
      getNestedErrorsObject: (batchPlace: BatchPlace, batchErrorsObject?: BatchErrorsObject) => {
        return batchErrorsObject?.batchPlaces?.find(
          (batchPlaceErrorsObject: BatchPlaceErrorsObject) => {
            return batchPlaceErrorsObject.clientId === batchPlace.clientId
          })
      },
      hasNestedErrorsObjects: (batchErrorsObject?: BatchErrorsObject) => {
        return isPresent(batchErrorsObject?.batchPlaces)
      },
      buildNewValue: () => ({
        clientId: uuidv4(),
        id: undefined,
        placeId: undefined,
        place: undefined,
        isAvailable: true
      }),
      fields: this.createBatchPlaceFields()
    })
  }

  private createBatches() {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return new ListFormField<ProductVariant, ProductVariantErrorsObject, Batch, BatchErrorsObject>({
      isAddObjectVisible: this.createBatchEditor().canCreate(),
      getRemoveObjectVisible: (batch: Batch) => this.createBatchEditor().canDestroy(batch.id),
      getVisible: () => this.objectPresentationLogic.getObject()?.hasBatches ?? false,
      getPlaceType: () => FormFieldPlaceType.ADDITIONAL,
      addObjectButtonName: coreTextProvider.add(),
      isEditVisible: true,
      getTitle: () => coreTextProvider.batches(),
      getValue: (productVariant: ProductVariant) => productVariant?.batches,
      getErrors: (productVariantErrorsObject?: ProductVariantErrorsObject) => productVariantErrorsObject
        ?.attributes
        ?.batches,
      setValue: (productVariant: ProductVariant, batches: Batch[] | null): ProductVariant => ({
        ...productVariant,
        batches
      }),
      getNestedObjectId: (batch: Batch) => batch.clientId!,
      getNestedObjectTitle: (_, index) => coreTextProvider.batch({ number: index + 1 }),
      getNestedErrorsObject: (batch: Batch, productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return productVariantErrorsObject?.batches?.find(
          (batchErrorsObject: BatchErrorsObject) => {
            return batchErrorsObject.clientId === batch.clientId
          })
      },
      hasNestedErrorsObjects: (productVariantErrorsObject?: ProductVariantErrorsObject) => {
        return isPresent(productVariantErrorsObject?.batches)
      },
      buildNewValue: (): Batch => this.createBatch(),
      fields: [
        this.createBatchPreview(),
        this.createBatchCode(),
        this.createBatchPrice(),
        this.createBatchOriginalPrice(),
        this.createBatchBonusPointsPrice(),
        this.createBatchPlaces()
      ]
    })
  }

  private createVariantImagesFields(afterValueChanged: (variantImage: VariantImage) => void) {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return [
      new ImageFormField<VariantImage, VariantImageErrorsObject, Image>({
        getDisabled: (variantImage: VariantImage) => this.createImageEditor().isImageDisabled(variantImage.id),
        getValue: (variantImage: VariantImage) => variantImage.image,
        setValue: (variantImage: VariantImage, image: Image | null | undefined) => {
          return {
            ...variantImage,
            image,
            imageId: image && image.id
          }
        },
        getErrors: (variantImageErrorsObject?: VariantImageErrorsObject) => variantImageErrorsObject
          ?.attributes?.imageId,
        getOptions: (image: Image) => this.createImageOptions(image),
        createObject: async({ file }) => {
          return await this.createImageUseCase.call({ file })
        },
        getOptionsExistAndLoading: (variantImage: VariantImage) => {
          return this.getImageOptionsExistAndLoading(variantImage.image)
        }
      }),
      new BooleanFormField<VariantImage, VariantImageErrorsObject>({
        getDisabled: (variantImage: VariantImage) => this.createImageEditor().isMainDisabled(variantImage.id),
        getValue: (variantImage: VariantImage) => variantImage.isMain,
        setValue: (variantImage: VariantImage, isMain: boolean) => ({
          ...variantImage,
          isMain
        }),
        getText: () => coreTextProvider.mainImage(),
        getErrors: (variantImageErrorsObject?: VariantImageErrorsObject) => variantImageErrorsObject
          ?.attributes?.isMain,
        afterValueChanged: afterValueChanged
      }),
      new StringFormField<VariantImage, VariantImageErrorsObject>({
        getDisabled: (variantImage: VariantImage) => this.createImageEditor().isExternalCodeDisabled(variantImage.id),
        getTitle: () => this.coreI18n.getTextProvider().externalCode(),
        getValue: (variantImage: VariantImage) => variantImage.image?.externalCode,
        setValue: (variantImage: VariantImage, externalCode: string) => ({
          ...variantImage,
          image: {
            ...variantImage.image ?? this.createImage(),
            externalCode
          }
        }),
        getErrors: (variantImageErrorsObject?: VariantImageErrorsObject) => {
          return variantImageErrorsObject?.image?.attributes?.externalCode
        }
      })
    ]
  }

  private createBatchPlaceFields() {
    const coreTextProvider = this.coreI18n.getTextProvider()
    return [
      new SingleSelectFormField<BatchPlace, BatchPlaceErrorsObject, Place>({
        getDisabled: (batchPlace: BatchPlace) => this.createBatchPlaceEditor().isPlaceDisabled(batchPlace.id),
        getObjects: async({
          query,
          lastObject
        }) => {
          return await this.getPlacesUseCase.call({
            filter: { query },
            pagination: { id: lastObject?.id ?? undefined }
          })
        },
        getValue: (batchPlace: BatchPlace) => batchPlace.place,
        setValue: (batchPlace: BatchPlace, place: Place | null) => ({
          ...batchPlace,
          place,
          placeId: place && place.id
        }),
        getErrors: (batchPlaceErrorsObject?: BatchPlaceErrorsObject) => batchPlaceErrorsObject
          ?.attributes
          ?.placeId,
        getOptionId: (place: Place) => place.id!.toString(),
        getOptionText: (place: Place) => place.name
      }),
      new BooleanFormField<BatchPlace, BatchPlaceErrorsObject>({
        getDisabled: (batchPlace: BatchPlace) => this.createBatchPlaceEditor().isAvailableDisabled(batchPlace.id),
        getValue: (batchPlace: BatchPlace) => batchPlace.isAvailable,
        setValue: (batchPlace: BatchPlace, isAvailable: boolean) => ({
          ...batchPlace,
          isAvailable
        }),
        getText: () => coreTextProvider.batchPlaceIsAvailable(),
        getErrors: (batchPlaceErrorsObject?: BatchPlaceErrorsObject) => batchPlaceErrorsObject
          ?.attributes?.isAvailable
      })
    ]
  }

  private getImageOptionsExistAndLoading(image: Image | null | undefined) {
    const attachmentExists = image?.attachmentExists ?? false
    return isPresent(image?.externalCode) && !attachmentExists
  }

  private createProductPropertyEditor() {
    return new ProductPropertyEditor({ configuration: this.configuration })
  }

  private createProductVariantEditor() {
    return new ProductVariantEditor({ configuration: this.configuration })
  }

  private createImageEditor() {
    return new ProductVariantProductImageEditor({ configuration: this.configuration })
  }

  private createOptionValueEditor() {
    return new ProductVariantOptionValueEditor({ configuration: this.configuration })
  }

  private createBatchEditor() {
    return new ProductVariantBatchEditor({ configuration: this.configuration })
  }

  private createBatchPlaceEditor() {
    return new ProductVariantBatchPlaceEditor({ configuration: this.configuration })
  }
}
