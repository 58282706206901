import ViewModel from "../../../../../../sqadmin/lib/view-model/ViewModel"
import BroadcastObjectsEventUseCase
  from "../../../../../../sqadmin/features/objects/domain/use-cases/objects/BroadcastObjectsEventUseCase"
import ObjectPresentationLogic
  from "../../../../../../sqadmin/features/objects/presentation/presentation-logics/ObjectPresentationLogic"
import { StateObservable } from "../../../../../../sqadmin/lib/view-model/StateObservable"
import { ObjectViewState } from "../../../../../../sqadmin/features/objects/presentation/view-states/ObjectViewState"
import ObjectViewEvent from "../../../../../../sqadmin/features/objects/presentation/view-events/ObjectViewEvent"
import autoBind from "auto-bind"
import isBlank from "../../../../../../sqadmin/lib/isBlank"
import StringFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/StringFormField"
import CoreTextProvider from "../../../../../core/i18n/CoreTextProvider"
import CoreI18n from "../../../../../core/i18n/CoreI18n"
import NumberFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/NumberFormField"
import ListFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/ListFormField"
import { v4 as uuidv4 } from "uuid"
import isPresent from "../../../../../../sqadmin/lib/isPresent"
import Property from "../../../../../core/domain/entities/properties/Property"
import PropertyError from "../../../../../core/domain/entities/properties/PropertyError"
import PropertyErrorsObject from "../../../../../core/domain/entities/properties/PropertyErrorsObject"
import PropertyValue from "../../../../../core/domain/entities/property-values/PropertyValue"
import PropertyValueErrorsObject from "../../../../../core/domain/entities/property-values/PropertyValueErrorsObject"
import CoreUrlProvider from "../../../../../core/presentation/services/CoreUrlProvider"
import BooleanFormField
  from "../../../../../../sqadmin/features/objects/presentation/entities/form-fields/form-field-by-type/BooleanFormField"
import CreatePropertyUseCase from "../../../../properties-core/domain/use-cases/CreatePropertyUseCase"
import GetPropertyUseCase from "../../../../properties-core/domain/use-cases/GetPropertyUseCase"
import UpdatePropertyUseCase from "../../../../properties-core/domain/use-cases/UpdatePropertyUseCase"
import DestroyPropertyUseCase from "../../../../properties-core/domain/use-cases/DestroyPropertyUseCase"

export default class PropertyViewModel extends ViewModel {
  private readonly coreI18n: CoreI18n
  private readonly coreUrlProvider: CoreUrlProvider
  private readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
  private readonly getPropertyUseCase: GetPropertyUseCase
  private readonly createPropertyUseCase: CreatePropertyUseCase
  private readonly updatePropertyUseCase: UpdatePropertyUseCase
  private readonly destroyPropertyUseCase: DestroyPropertyUseCase
  private readonly propertyId?: number

  private readonly objectPresentationLogic: ObjectPresentationLogic<
    Property,
    PropertyError,
    PropertyErrorsObject
  >

  readonly observableObjectViewState: StateObservable<ObjectViewState>

  constructor(parameters: {
    readonly coreI18n: CoreI18n
    readonly broadcastObjectsEventUseCase: BroadcastObjectsEventUseCase
    readonly getPropertyUseCase: GetPropertyUseCase
    readonly createPropertyUseCase: CreatePropertyUseCase
    readonly updatePropertyUseCase: UpdatePropertyUseCase
    readonly destroyPropertyUseCase: DestroyPropertyUseCase
    readonly propertyId?: number
  }) {
    super()
    this.coreI18n = parameters.coreI18n
    this.broadcastObjectsEventUseCase = parameters.broadcastObjectsEventUseCase
    this.getPropertyUseCase = parameters.getPropertyUseCase
    this.createPropertyUseCase = parameters.createPropertyUseCase
    this.updatePropertyUseCase = parameters.updatePropertyUseCase
    this.destroyPropertyUseCase = parameters.destroyPropertyUseCase
    this.propertyId = parameters.propertyId
    this.objectPresentationLogic = this.createObjectPresentationLogic()
    this.observableObjectViewState = this.objectPresentationLogic.observableObjectViewState
    this.coreUrlProvider = new CoreUrlProvider()
    autoBind(this)
  }

  onViewObjectEvent(objectViewEvent: ObjectViewEvent) {
    this.objectPresentationLogic.onObjectViewEvent(objectViewEvent)
  }

  private createObjectPresentationLogic(): ObjectPresentationLogic<
    Property,
    PropertyError,
    PropertyErrorsObject
  > {
    const coreTextProvider: CoreTextProvider = this.coreI18n.getTextProvider()
    return new ObjectPresentationLogic({
      // TODO: how to not pass?
      broadcastObjectsEventUseCase: this.broadcastObjectsEventUseCase,
      isNewObject: isBlank(this.propertyId),
      getObjectUrl: (property) => {
        return this.coreUrlProvider.buildPropertyUrl({
          id: property.id!
        })
      },
      buildObject: async() => ({
        shouldDisplayInFilters: true,
        shouldDisplayInProduct: true,
        values: undefined
      }),
      loadObject: async() => {
        return await this.getPropertyUseCase.call({ propertyId: this.propertyId! })
      },
      createObject: async({ object: property }) => {
        return await this.createPropertyUseCase.call({ property })
      },
      updateObject: async({ object: property }) => {
        return await this.updatePropertyUseCase.call({
          propertyId: this.propertyId!,
          property
        })
      },
      destroyObject: async() => {
        return await this.destroyPropertyUseCase.call({ propertyId: this.propertyId! })
      },
      getErrorsObject: ({ error: propertyError }) => propertyError
        ?.errorsObject,
      formFields: [
        new StringFormField<Property, PropertyErrorsObject>({
          getTitle: () => coreTextProvider.name(),
          getValue: (property: Property) => property.name,
          setValue: (property: Property, name: string) => ({
            ...property,
            name
          }),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject
            ?.attributes?.name
        }),
        new StringFormField<Property, PropertyErrorsObject>({
          getDisabled: () => true,
          getTitle: () => coreTextProvider.externalCode(),
          getValue: (property: Property) => property.externalCode,
          setValue: (property: Property, externalCode: string) => ({
            ...property,
            externalCode
          }),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject
            ?.attributes?.externalCode
        }),
        new NumberFormField<Property, PropertyErrorsObject>({
          getTitle: () => coreTextProvider.position(),
          getValue: (property: Property) => property.position,
          setValue: (property: Property, position: number | null) => ({
            ...property,
            position
          }),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject?.attributes?.position
        }),
        new StringFormField<Property, PropertyErrorsObject>({
          getTitle: () => coreTextProvider.uom(),
          getValue: (property: Property) => property.uom,
          setValue: (property: Property, uom: string) => ({
            ...property,
            uom
          }),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject
            ?.attributes?.uom
        }),
        new BooleanFormField<Property, PropertyErrorsObject>({
          getValue: (property: Property) => property.shouldDisplayInProduct,
          setValue: (property: Property, shouldDisplayInProduct: boolean) => ({
            ...property,
            shouldDisplayInProduct
          }),
          getText: () => coreTextProvider.displayInProduct(),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject
            ?.attributes
            ?.shouldDisplayInProduct
        }),
        new BooleanFormField<Property, PropertyErrorsObject>({
          getValue: (property: Property) => property.shouldDisplayInFilters,
          setValue: (property: Property, shouldDisplayInFilters: boolean) => ({
            ...property,
            shouldDisplayInFilters
          }),
          getText: () => coreTextProvider.displayInFilters(),
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject
            ?.attributes
            ?.shouldDisplayInFilters
        }),
        new ListFormField<Property, PropertyErrorsObject, PropertyValue, PropertyValueErrorsObject>({
          isSwitchPositionVisible: true,
          getTitle: () => coreTextProvider.propertyValues(),
          getValue: (property: Property) => property.values,
          getErrors: (propertyErrorsObject?: PropertyErrorsObject) => propertyErrorsObject?.attributes?.values,
          setValue: (property: Property, values: PropertyValue[] | null): Property => ({
            ...property,
            values
          }),
          getNestedObjectId: (propertyValue: PropertyValue) => propertyValue.clientId!,
          getNestedObjectTitle: () => coreTextProvider.propertyValue(),
          getNestedErrorsObject: (propertyValue: PropertyValue, propertyErrorsObject?: PropertyErrorsObject) => {
            return propertyErrorsObject?.values?.find(
              (propertyValueErrorsObject: PropertyValueErrorsObject) => {
                return propertyValueErrorsObject.clientId === propertyValue.clientId
              })
          },
          getPosition: (propertyValue: PropertyValue) => propertyValue.position!,
          setPosition: (propertyValue: PropertyValue, position: number): PropertyValue => {
            return { ...propertyValue, position }
          },
          buildNewValue: (property: Property) => this.createNewPropertyValue(property),
          fields: [
            new StringFormField<PropertyValue, PropertyValueErrorsObject>({
              getTitle: () => coreTextProvider.name(),
              getValue: (propertyValue: PropertyValue) => propertyValue.name,
              setValue: (propertyValue: PropertyValue, name: string) => {
                return {
                  ...propertyValue,
                  name
                }
              },
              getErrors: (propertyValueErrorsObject?: PropertyValueErrorsObject) => propertyValueErrorsObject
                ?.attributes?.name
            }),
            new StringFormField<PropertyValue, PropertyValueErrorsObject>({
              getDisabled: () => true,
              getTitle: () => coreTextProvider.externalCode(),
              getValue: (propertyValue: PropertyValue) => propertyValue.externalCode,
              setValue: (propertyValue: PropertyValue, externalCode: string) => ({
                ...propertyValue,
                externalCode
              }),
              getErrors: (propertyValueErrorsObject?: PropertyValueErrorsObject) => propertyValueErrorsObject
                ?.attributes?.externalCode
            })
          ]
        })
      ]
    })
  }

  private createNewPropertyValue(property: Property | null | undefined): PropertyValue {
    const maxPosition = this.detectMaxPosition(property)
    const position = isPresent(maxPosition) ? maxPosition + 1 : 1

    return {
      clientId: uuidv4(),
      position: position,
      externalCode: undefined,
      name: undefined,
      id: undefined
    }
  }

  private detectMaxPosition(property: Property | null | undefined): number | null | undefined {
    const propertyPositions = property
      ?.values
      ?.map(value => value.position)
      ?.filter((position): position is number => isPresent(position))

    if (isBlank(propertyPositions) || propertyPositions.length === 0) return null

    return Math.max(...propertyPositions)
  }
}
