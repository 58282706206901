import NetworkPlace from "../entities/places/NetworkPlace"
import Place from "../../domain/entities/places/Place"
import SettlementsMapper from "./SettlementsMapper"

export default class PlacesMapper {
  mapNetworkToDomain({
    place
  }: {
    readonly place: NetworkPlace
  }): Place {
    return {
      id: place.id,
      externalCode: place.externalCode,
      description: place.description,
      name: place.name,
      isPickupPoint: place.isPickupPoint,
      settlementId: place.settlementId,
      settlement: place.settlement && new SettlementsMapper().mapNetworkToDomain({
        settlement: place.settlement
      }),
      code: place.code
    }
  }

  mapDomainToNetwork({
    place
  }: {
    readonly place: Place
  }): NetworkPlace {
    return new NetworkPlace({
      id: place.id,
      externalCode: place.externalCode,
      description: place.description,
      name: place.name,
      isPickupPoint: place.isPickupPoint,
      settlementId: place.settlementId,
      settlement: place.settlement && new SettlementsMapper().mapDomainToNetwork({
        settlement: place.settlement
      }),
      code: place.code
    })
  }
}
