import { Expose, Type } from "class-transformer"
import DisplayStyle from "../../../domain/entities/options/DisplayStyle"
import NetworkOptionValue from "../option-values/NetworkOptionValue"

export default class NetworkOption {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly externalCode?: string | null

  @Expose()
  readonly name?: string | null

  @Expose()
  readonly position?: number | null

  @Expose()
  readonly displayStyle?: DisplayStyle | null

  @Expose()
  @Type(() => NetworkOptionValue)
  readonly values?: NetworkOptionValue[] | null

  constructor(parameters?: {
    readonly id?: number | null
    readonly externalCode?: string | null
    readonly name?: string | null
    readonly position?: number | null
    readonly displayStyle?: DisplayStyle | null
    readonly values?: NetworkOptionValue[] | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.externalCode = parameters.externalCode
    this.name = parameters.name
    this.position = parameters.position
    this.displayStyle = parameters.displayStyle
    this.values = parameters.values
  }
}
