import { Expose, Type } from "class-transformer"
import NetworkPropertyValue from "../property-values/NetworkPropertyValue"
import NetworkProperty from "../properties/NetworkProperty"

export default class NetworkProductProperty {
  @Expose()
  readonly id?: number | null

  @Expose()
  readonly propertyId?: number | null

  @Expose()
  readonly propertyValueId?: number | null

  @Expose()
  readonly clientId?: string | null

  @Expose()
  @Type(() => NetworkProperty)
  readonly property?: NetworkProperty | null

  @Expose()
  @Type(() => NetworkPropertyValue)
  readonly propertyValue?: NetworkPropertyValue | null

  constructor(parameters?: {
    readonly id?: number | null
    readonly propertyId?: number | null
    readonly propertyValueId?: number | null
    readonly clientId?: string | null
    readonly property?: NetworkProperty | null
    readonly propertyValue?: NetworkPropertyValue | null
  }) {
    if (parameters === undefined) {
      return
    }

    this.id = parameters.id
    this.propertyId = parameters.propertyId
    this.propertyValueId = parameters.propertyValueId
    this.clientId = parameters.clientId
    this.property = parameters.property
    this.propertyValue = parameters.propertyValue
  }
}
